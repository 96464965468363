import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { InView } from 'react-intersection-observer'
import { GuildAccessPill } from 'src/molecules/GuildAccessPill'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { GuildAccessNotch } from '@/molecules/GuildAccessNotch'
import { LandscapeRowCollection, LandscapeTileCard } from '@/molecules/LandscapeRowCollection'
import { createWebClient } from '@/services/ApolloClient'
import { useGuildUser } from '@/services/GuildUserService'
import { Episode, Project } from '@/services/ProjectsService'
import { LinkViewModel } from '@/services/RenderService'
import { useUser } from '@/services/UserService'
import {
  GetTopPicksForYouQuery,
  RecommendationTrack,
  TrackRecommendationMutation,
  TrackRecommendationMutationVariables,
} from '@/types/codegen-federation'
import { buildLinkToEpisode } from '@/utils/EpisodeUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { isDefined } from '@/utils/types'

export function TopPicksForYouCollection() {
  const track = useSafeTrack()
  const { userId, isLoggedIn } = useUser()
  // TODO: when web implements user profiles, use that profile id here instead.
  const viewerProfileId = userId ?? undefined
  const { isGuildMember } = useGuildUser()
  const { t } = useTranslate('watch')
  const { locale } = useLocale()
  const { trackRecommendation } = useRecommendationTracking()
  const [contentLinks, setContentLinks] = React.useState<TopPicksLinkViewModel[]>([])

  const client = React.useMemo(() => {
    return createWebClient({ locale, region: 'US', viewerProfileId })
  }, [locale, viewerProfileId])

  useQuery<GetTopPicksForYouQuery>(GET_TOP_PICKS_FOR_YOU_QUERY, {
    client,
    errorPolicy: 'all',
    skip: !isLoggedIn,
    onCompleted: (data) => {
      const links = data?.startWatching?.content?.map(mapContentLink.bind(null, t)).filter(isDefined) ?? []
      setContentLinks(links)
    },
  })

  if (contentLinks.length < 4) return null

  return (
    <LandscapeRowCollection testId="top-picks-for-you-collection" title={t('topPicksForYou', 'Top Picks For You')}>
      {contentLinks.map((contentLink) => {
        const metadata = contentLink.metadata as { episode?: Episode; project?: Project }
        const episode = metadata?.episode

        return (
          <React.Fragment key={contentLink.imageUrl}>
            <InView
              onChange={(inView) => {
                if (inView) {
                  trackRecommendation({
                    type: 'IMPRESSION',
                    items: [{ id: contentLink.id! }],
                  })
                }
              }}
            >
              <LandscapeTileCard
                className="mb-2 mr-4"
                linkModel={contentLink}
                onClick={() => {
                  track('Top Picks For You Thumbnail Clicked', { contentId: contentLink.id })
                  trackRecommendation({
                    type: 'PRESS',
                    items: [{ id: contentLink.id! }],
                  })
                }}
              >
                <div className="absolute inset-0">
                  <GuildAccessPill
                    isGuildMember={isGuildMember}
                    earlyAccessDate={episode?.earlyAccessDate ? new Date(episode.earlyAccessDate) : undefined}
                    unavailableReason={episode?.unavailableReason}
                  />
                  {episode?.earlyAccessDate && (
                    <GuildAccessNotch
                      earlyAccessDate={new Date(episode.earlyAccessDate)}
                      publiclyAvailableDate={
                        episode.publiclyAvailableDate ? new Date(episode.publiclyAvailableDate) : undefined
                      }
                      isGuildMember={isGuildMember}
                    />
                  )}
                </div>
                {!episode?.unavailableReason && <PlayButtonThumbnailOverlay />}
              </LandscapeTileCard>
              <TitleAndSubtitleDisplay className="mr-4" title={contentLink.eyebrow} subtitle={contentLink.subtitle} />
            </InView>
          </React.Fragment>
        )
      })}
    </LandscapeRowCollection>
  )
}

const GET_TOP_PICKS_FOR_YOU_QUERY = gql`
  query getTopPicksForYou {
    startWatching {
      content {
        id
        title {
          ... on ContentSeries {
            project {
              slug
            }
          }
          ... on ContentMovie {
            id
            project {
              id
              slug
            }
          }
          ... on ContentDisplayable {
            id
            name
            image(aspect: "16:9", category: ANGEL_KEY_ART_2) {
              cloudinaryPath
            }
          }
        }
        watchable {
          __typename
          ... on Node {
            id
          }
          ... on ContentDisplayable {
            id
            name
          }
          ... on ContentEpisode {
            id
            name
            subtitle
          }
        }
      }
    }
  }
`

interface TopPicksLinkViewModel extends LinkViewModel {
  eyebrow?: string
  subtitle?: string
}

type QueryContent = NonNullable<NonNullable<GetTopPicksForYouQuery['startWatching']>['content']>[number]
type QueryEpisode = QueryContent & { watchable: { __typename: 'ContentEpisode' } }
type QuerySpecial = QueryContent & { watchable: { __typename: 'ContentSpecial' } }
type QueryMovie = QueryContent & { watchable: { __typename: 'ContentMovie' } }
function mapContentLink(t: TranslateFunction, content: QueryContent): TopPicksLinkViewModel | undefined {
  if (!content) return undefined

  const imageUrl = content.title?.image?.cloudinaryPath
  if (!imageUrl) return undefined

  return {
    id: content.id ?? undefined,
    imageUrl,
    eyebrow: formatEyebrow(content, t),
    subtitle: formatSubtitle(content),
    linkUrl: buildLinkToEpisode({
      projectSlug: content.title?.project.slug as string,
      guid: content.watchable?.id as string,
    }),
  }
}

function formatEyebrow(content: QueryContent, t: TranslateFunction) {
  if (!content) return undefined

  if (content.title?.name?.toLowerCase() === 'minisode') {
    return t('minisode', 'Minisode')
  } else if (isEpisode(content)) {
    return t('seriesDescription', 'series')
  } else if (isSpecial(content)) {
    return t('special', 'Special')
  } else if (isMovie(content)) {
    return t('film', 'Film')
  }
}

function formatProjectTitle(content: QueryContent) {
  return content?.title?.name ?? undefined
}

function formatSubtitle(content: QueryContent) {
  return formatProjectTitle(content)
}

function isEpisode(content: QueryContent): content is QueryEpisode {
  return content?.watchable?.__typename === 'ContentEpisode'
}

function isSpecial(content: QueryContent): content is QuerySpecial {
  return content?.watchable?.__typename === 'ContentSpecial'
}

function isMovie(content: QueryContent): content is QueryMovie {
  return content?.watchable?.__typename === 'ContentMovie'
}

// lightly adapted from: https://www.notion.so/angelstudios/API-s-For-Client-November-24-139dbf5b32db8035bd9edacd43060d2b?pvs=4#139dbf5b32db809ca9ded98c6fba687e
export const TRACK_RECOMMENDATION = gql`
  mutation trackRecommendation(
    $impressions: TrackRecommendationInput!
    $presses: TrackRecommendationInput!
    $includeImpressions: Boolean!
    $includePresses: Boolean!
  ) {
    impressions: trackRecommendation(input: $impressions) @include(if: $includeImpressions)
    presses: trackRecommendation(input: $presses) @include(if: $includePresses)
  }
`

type ThrottledTracker = {
  tracked: Set<string>
  throttled: RecommendationTrack[]
}

type TrackInput = {
  type: 'IMPRESSION' | 'PRESS'
  items: {
    id: string
  }[]
}

export const useRecommendationTracking = () => {
  const [trackRecommendationMutation] = useMutation<TrackRecommendationMutation, TrackRecommendationMutationVariables>(
    TRACK_RECOMMENDATION,
  )

  const throttledImpressions = React.useRef<ThrottledTracker>({ tracked: new Set<string>(), throttled: [] })
  const throttledPresses = React.useRef<ThrottledTracker>({ tracked: new Set<string>(), throttled: [] })

  const flushTrackRecommendations = React.useCallback(() => {
    if (!throttledImpressions.current.throttled.length && !throttledPresses.current.throttled.length) {
      return
    }

    const impressions = throttledImpressions.current.throttled
    throttledImpressions.current.throttled = []

    const presses = throttledPresses.current.throttled
    throttledPresses.current.throttled = []

    return trackRecommendationMutation({
      variables: {
        impressions: {
          type: 'IMPRESSION',
          items: impressions,
        },
        presses: {
          type: 'PRESS',
          items: presses,
        },
        includeImpressions: impressions.length > 0,
        includePresses: presses.length > 0,
      },
    })
  }, [trackRecommendationMutation])

  React.useEffect(() => {
    const flushInterval = setInterval(flushTrackRecommendations, 10000)

    return () => {
      clearInterval(flushInterval)
      flushTrackRecommendations()
    }
  }, [flushTrackRecommendations])

  const trackRecommendation = React.useCallback(async (trackInput: TrackInput) => {
    const throttledTracker = trackInput.type === 'PRESS' ? throttledPresses.current : throttledImpressions.current

    const items: RecommendationTrack[] = trackInput.items
      .filter((item) => {
        if (!item?.id || throttledTracker.tracked.has(item.id)) {
          return false
        }

        throttledTracker.tracked.add(item.id)
        return true
      })
      .map((item) => ({ id: item.id, timestamp: new Date().toISOString() }))

    throttledTracker.throttled.push(...items)
  }, [])

  return { trackRecommendation, flushTrackRecommendations }
}
