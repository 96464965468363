import { gql } from '@apollo/client'
import { CurrentSmpte, Frame } from '@/organisms/AngelPlayer'
import { getWebClient } from '@/services/ApolloClient'

export const GET_FRAMES_FROM_FRAMENUMBER = gql`
  query getNiftyFramesByTimeAndEpisodeGlobalId($frameNumber: Int!, $episodeGlobalId: String!) {
    getNiftyFramesByTimeAndEpisodeGlobalId(frameNumber: $frameNumber, episodeGlobalId: $episodeGlobalId) {
      frames {
        smpteTimecode
        frameNumber
        nifty {
          id
          screenName
          activeListing {
            price
            status
          }
        }
        user {
          profile {
            image(fallbackToInitials: true)
          }
        }
      }
    }
  }
`

export function getFrameNumberFromSMPTE(smpte: CurrentSmpte | string): number {
  const smpteToParse = smpte === 'string' ? smpte : (smpte as CurrentSmpte)?.full || ''

  const parts = smpteToParse.split(':')
  const hours = Number(parts[0])
  const minutes = Number(parts[1])
  const seconds = Number(parts[2])
  const frame = Number(parts[3])

  return ((hours * 60 + minutes) * 60 + seconds) * 24 + frame
}

interface getCurrentFrameProps {
  currentSMPTE: CurrentSmpte
  episodeSlug: string
  frames: Frame[]
  setFrames: (newFrames: Frame[]) => void
}

export async function getCurrentFrame({
  currentSMPTE,
  episodeSlug,
  frames,
  setFrames,
}: getCurrentFrameProps): Promise<Frame | null> {
  if (!currentSMPTE || !episodeSlug) return null

  const frameNumber = getFrameNumberFromSMPTE(currentSMPTE)

  const existingFrame = findFrame({ frames, frameNumber })

  if (existingFrame) return existingFrame

  const newFrames = await getFrames({ frameNumber, episodeSlug })

  setFrames(newFrames)

  return findFrame({ frames: newFrames, frameNumber })
}

interface FrameLookupRequest {
  frames: Frame[]
  frameNumber: number
}

export function shouldFetchMoreFrames({ frames, frameNumber }: FrameLookupRequest): boolean {
  if (!frames) return true
  const numberOfFrames = frames.length
  const currentIndex = getCurrentFrameIndex({ frames, frameNumber })

  return currentIndex < 5 || currentIndex > numberOfFrames - 5
}

export function getCurrentFrameIndex({ frames, frameNumber }: FrameLookupRequest) {
  return frames.findIndex?.((frame) => frame.frameNumber === frameNumber)
}

function findFrame({ frames, frameNumber }: { frames: Frame[]; frameNumber: number }): Frame | null {
  const frame = frames?.find?.((frame) => frame.frameNumber === frameNumber)
  return frame || null
}

export async function getFrames({ frameNumber, episodeSlug, locale }: GetFramesRequest): Promise<Frame[]> {
  const client = getWebClient({ locale })
  const { data } = await client.query({
    query: GET_FRAMES_FROM_FRAMENUMBER,
    variables: {
      frameNumber,
      episodeGlobalId: episodeSlug,
    },
    errorPolicy: 'all',
  })

  return data.getNiftyFramesByTimeAndEpisodeGlobalId?.map(
    (niftyFrame: NiftyFrameResponseObject) => niftyFrame?.frames?.[0],
  )
}

interface GetFramesRequest {
  frameNumber: number
  episodeSlug: string
  locale?: string
}

interface NiftyFrameResponseObject {
  id: string
  frames: Frame[]
}
