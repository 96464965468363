import { RequestCookies } from 'next/dist/compiled/@edge-runtime/cookies'
import {
  EVERFLOW_TRANSACTION_ID_COOKIE,
  SEGMENT_ANONYMOUS_ID_COOKIE,
  SEGMENT_USER_ID_COOKIE,
} from '@/constants/cookies'
import { AnalyticsEvent } from '@/utils/analytics'
import { isProductionEnvironment } from './environment-utils'

export const trackAttribution = ({
  cookies,
  event,
  properties,
  anonymousId,
  userId,
}: {
  cookies: RequestCookies
  event: AnalyticsEvent
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  properties?: any
  anonymousId?: string
  userId?: string
}) => {
  const aidCookie = cookies.get(SEGMENT_ANONYMOUS_ID_COOKIE)?.value
  const uidCookie = cookies.get(SEGMENT_USER_ID_COOKIE)?.value
  const efTransactionIdCookie = cookies.get(EVERFLOW_TRANSACTION_ID_COOKIE)?.value

  const trackParams: SegmentNodeTrackParams = {
    properties,
    anonymousId: aidCookie || anonymousId || '',
    userId: uidCookie ?? userId ?? null,
    [EVERFLOW_TRANSACTION_ID_COOKIE]: efTransactionIdCookie,
  }

  track(event, trackParams)
}

export interface SegmentNodeTrackParams {
  anonymousId: string
  [key: string]: unknown
}

// Documentation for http API https://segment.com/docs/connections/sources/catalog/libraries/server/http-api/
// We need to use fetch here until we upgrade NextJS to allow us to use the @segment/analytics-node package
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export async function track(eventName: AnalyticsEvent, { anonymousId, ...properties }: SegmentNodeTrackParams) {
  const preparedSegmentKey = `${process.env.NEXT_PUBLIC_SEGMENT_KEY as string}:` // extra colon needed at the end. See docs above
  const encodedSegmentKey = Buffer.from(preparedSegmentKey, 'utf-8').toString('base64')

  const body = {
    environment: process.env.NEXT_PUBLIC_ANGEL_ENV,
    event: eventName,
    anonymousId,
    properties,
  }

  if (isProductionEnvironment()) {
    return fetch(`https://api.segment.io/v1/track`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json', Authorization: `Basic ${encodedSegmentKey}` },
    })
  } else {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(body, null, 2))
  }
}
