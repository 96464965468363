/* eslint-disable @typescript-eslint/no-explicit-any */
import { Case, Default, Switch } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { GuildIconMono } from '@/atoms/Icons/GuildIconMono'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { CaptionXS, LabelSM, ParagraphSM, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants'
import { LandscapeRowCollection } from '@/molecules/LandscapeRowCollection'
import { PortraitRowCollection } from '@/molecules/PortraitRowCollection'
import { FranchiseExtended } from '@/services/FranchiseService'
import { useGuildUser } from '@/services/GuildUserService'
import { inTheaters, isSellingTickets } from '@/services/PhaseManagerService'
import { Project } from '@/services/ProjectsService'
import { FranchiseComponent } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { TypedTrackFn, useSafeTrack } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

export interface FranchiseComponentCallToActionProps {
  franchise: FranchiseExtended
  franchiseComponent: FranchiseComponent
}

export const FranchiseComponentCallToAction: React.FC<FranchiseComponentCallToActionProps> = ({
  franchise,
  franchiseComponent,
}) => {
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const { isGuildMember } = useGuildUser()
  const logoUrl = franchise?.images?.[0]?.cloudinaryPath

  const buttonText =
    isGuildMember && franchiseComponent?.ctaGuildButtonText
      ? franchiseComponent.ctaGuildButtonText
      : franchiseComponent?.ctaButtonText
      ? franchiseComponent.ctaButtonText
      : null
  const buttonHref =
    isGuildMember && franchiseComponent?.ctaGuildButtonHref
      ? franchiseComponent.ctaGuildButtonHref
      : franchiseComponent?.ctaButtonHref
      ? franchiseComponent.ctaButtonHref
      : null

  return (
    <div className="relative z-30 -mt-16 w-full md:mt-[72px] lg:max-w-[1680px] 3lg:mx-auto">
      <div className="px-4 md:px-8 lg:px-16 xl:px-32 2xl:px-40">
        {logoUrl && (
          <img
            alt="Franchise Logo Image"
            className="w-auto max-w-[167px] md:max-w-[222px] lg:max-w-[380px]"
            src={getCloudinaryImageUrl({
              path: logoUrl,
              width: 380,
              format: 'webp',
              transforms: 'e_trim,q_auto:best',
            })}
          />
        )}{' '}
        <ParagraphSM
          color="core-gray-400"
          className="lg:photon-paragraph-md 2xl:photon-paragraph-lg mb-6 mt-4 max-w-[286px] lg:max-w-[356px] 2xl:max-w-[450px]"
        >
          {franchiseComponent?.paragraphDescriptionOverride ?? franchise?.shortDescription}
        </ParagraphSM>
      </div>
      <div className="mb-8 flex gap-6 px-4 md:px-8 lg:px-16 xl:px-32 2xl:px-40">
        {buttonHref && buttonText && (
          <LinkButton
            variant="white"
            href={buttonHref}
            onClick={() => track('Clicked Franchise Component CTA Learn More', { franchise: franchise?.slug })}
            className="flex min-h-10 w-fit items-center justify-center gap-1 lg:px-4 xl:px-6"
          >
            <Switch>
              <Case
                condition={
                  franchiseComponent?.ctaButtonIcon === 'ticket' ||
                  (isGuildMember && franchiseComponent?.ctaGuildButtonIcon === 'ticket')
                }
              >
                <TicketIcon color="black" className="mr-2" />
              </Case>
              <Case
                condition={
                  franchiseComponent?.ctaButtonIcon === 'guild' ||
                  (isGuildMember && franchiseComponent?.ctaGuildButtonIcon === 'guild')
                }
              >
                <GuildIconMono color="black" size={22} className="mr-2" />
              </Case>
              <Case
                condition={
                  franchiseComponent?.ctaButtonIcon === 'pif' ||
                  (isGuildMember && franchiseComponent?.ctaGuildButtonIcon === 'pif')
                }
              >
                <PayItForwardIcon color="black" size={18} className="mr-2" />
              </Case>
              <Default>{null}</Default>
            </Switch>
            <LabelSM className="xl:photon-label-md">{buttonText}</LabelSM>
          </LinkButton>
        )}
        <LinkButton
          variant="core-gray-900"
          href={`${paths.franchise.index}/${franchise?.slug}`}
          onClick={() => track('Clicked Franchise Component CTA', { franchise: franchise?.slug })}
          className="flex min-h-10 w-fit items-center justify-center gap-1 !border-[#434343] !bg-[#434343] text-white lg:px-4 xl:px-6"
        >
          <LabelSM className="xl:photon-label-md">{t('learnMore', 'Learn More')}</LabelSM>
        </LinkButton>
      </div>
      <div className="flex w-full flex-col gap-4">
        <TitleXS
          weight="semibold"
          className="xl:photon-title-sm 2xl:photon-title-md px-4 md:px-8 lg:px-16 xl:px-32 2xl:px-40"
        >
          {t('shows', 'Shows')}
        </TitleXS>
        {franchise?.projects?.length && franchise.projects.length > 0 && (
          <ProjectRail franchise={franchise} t={t} track={track} />
        )}
      </div>
    </div>
  )
}

interface ProjectRailProps {
  franchise: FranchiseExtended
  t: TranslateFunction
  track: TypedTrackFn
}

const ProjectRail: React.FC<ProjectRailProps> = ({ franchise, t, track }) => {
  return (
    <div className="w-full overflow-x-hidden px-4 pb-10 scrollbar-none md:px-8 lg:px-16 xl:px-32 2xl:px-40">
      <div className="inline-block h-full w-full md:hidden">
        <PortraitRowCollection haltLazyLoad>
          {franchise &&
            franchise?.projects?.map((project: Project | any) => {
              if (!project) return null

              const sellingTickets = isSellingTickets(project.primaryFlowPhases ?? [])

              const href = sellingTickets
                ? `${paths.tickets.index}/${project.slug}`
                : `${paths.watch.index}/${project.slug}`

              const portraitProjectPoster =
                project?.title?.portraitAngelImage?.cloudinaryPath ?? project?.title?.portraitTitleImage?.cloudinaryPath

              return (
                <a
                  key={project?.slug}
                  className="pb-2 pr-4"
                  href={href}
                  onClick={() => track('Clicked Franchise Component Project', { project: project?.slug })}
                >
                  <div className="inline-block h-fit duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05] ">
                    <Image
                      aria-label={project?.name as string}
                      alt={project?.name as string}
                      src={portraitProjectPoster as string}
                      height={490}
                      width={294}
                      className="rounded-lg bg-black"
                    />
                  </div>
                </a>
              )
            })}
        </PortraitRowCollection>
      </div>
      <div className="hidden h-full w-full md:inline-block">
        <LandscapeRowCollection slidesToScroll={3} slidesToShow={3}>
          {franchise &&
            franchise?.projects?.map((project: Project | any) => {
              if (!project) return null

              const sellingTickets = isSellingTickets(project.primaryFlowPhases ?? [])
              const isInTheaters = inTheaters(project.primaryFlowPhases ?? [])

              const href = sellingTickets
                ? `${paths.tickets.index}/${project.slug}`
                : `${paths.watch.index}/${project.slug}`

              const landscapeProjectPoster =
                project?.title?.landscapeAngelImage?.cloudinaryPath ??
                project?.title?.landscapeTitleImage?.cloudinaryPath

              return (
                <a
                  key={project?.slug}
                  className="pb-2 pr-4"
                  href={href}
                  onClick={() => track('Clicked Franchise Component Project', { project: project?.slug })}
                >
                  <div className="relative inline-block h-fit duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05] ">
                    <Image
                      aria-label={project?.name as string}
                      alt={project?.name as string}
                      src={landscapeProjectPoster as string}
                      height={253}
                      width={450}
                      className="rounded-lg bg-black"
                    />
                    <div className="absolute bottom-3 right-2 z-10 p-1">
                      {isInTheaters && (
                        <CaptionXS className="whitespace-nowrap rounded-md bg-core-gray-900/90 px-[10px] py-[5px] text-right uppercase backdrop-blur-lg">
                          {t('inTheatersNow', 'In Theaters Now')}
                        </CaptionXS>
                      )}
                    </div>
                  </div>
                </a>
              )
            })}
        </LandscapeRowCollection>
      </div>
    </div>
  )
}
