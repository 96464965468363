import { Maybe } from 'graphql/jsutils/Maybe'
import { slugs } from '@/constants/slugs'

interface Params {
  slug: string
  public?: Maybe<boolean>
}

export const DO_NOT_INDEX_PROJECT_SLUGS = [
  slugs.forYourConsideration,
  slugs.theChosen,
  'angel-chosen',
  'content-staging-sequel-89b90e42-0a14-4894-9b2c-a53908037797',
  'content_graveyard_c28d7d21-3496-48b8-b586-78da75e3d95a',
  'for-your-consideration-staging',
  'image-test',
  'jungle-beat-staging',
  'staging-for-content',
]

// NOTE `public` is a reserved keyword. This is the name of the property we get from the api, so it is most convenient to keep it as is.
// This however requires that we access `public` as a property on the object without destructuring it.
export const shouldHideFromSearchEngines = (params: Params) => {
  if (!params.public) return true

  const slug = params.slug
  return !slug || DO_NOT_INDEX_PROJECT_SLUGS.some((s) => s === slug)
}
