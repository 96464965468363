import { setCookie } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { AppFeatures } from '@/experimentation/app-features'
import { getGrowthbook } from '@/experimentation/utils'
import { getAnonymousId } from '@/services/requests'
import { track } from '@/utils/SegmentNode'
import { getUserUuidFromJwt } from '@/utils/users/usersServer'

export const initializeGrowthbookOnServer = async (
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
) => {
  const { anonymousId, shouldSetCookie } = getAnonymousId(req.cookies)
  if (shouldSetCookie) {
    setCookie(SEGMENT_ANONYMOUS_ID_COOKIE, anonymousId, {
      maxAge: Durations.TEN_YEARS_IN_SECONDS,
      path: '/',
      res,
      req,
    })
  }

  const growthbook = getGrowthbook(
    {
      subscribeToChanges: false,
      backgroundSync: false,
    },
    async (eventName, payload) => {
      return await track(eventName, { anonymousId, ...payload })
    },
  )

  // Give GB two attempts to initialize
  const gbRes = await growthbook.init()
  if (gbRes.error) await growthbook.init()

  const userId = getUserUuidFromJwt(req, res) ?? null

  await growthbook.setAttributes({ id: anonymousId, userId })
  return growthbook
}

export const setupServerExperiments = async (
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
) => {
  const growthbook = await initializeGrowthbookOnServer(req, res)
  return {
    getFeatureValue: growthbook.getFeatureValue.bind(growthbook),
    destroy: growthbook.destroy.bind(growthbook),
    anonymousId: growthbook.getAttributes().id,
  }
}

export const runExperimentOnServer = async <T extends keyof AppFeatures>(
  experimentKey: T,
  defaultValue: AppFeatures[T],
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
) => {
  const url = new URL(req.url as string, `http://${req.headers.host}`)
  const fromQuery = url.searchParams.get(experimentKey)
  if (fromQuery !== null) {
    if (fromQuery === 'true') return true
    if (fromQuery === 'false') return false
    if (fromQuery === 'FFA41C') return '#FFA41C'
    if (fromQuery === 'FFD712') return '#FFD712'
    if (fromQuery === '21CEA0') return '#21CEA0'
    // TODO remove these after the guild_email_input_button_color experiment is called
    return fromQuery
  }

  const { getFeatureValue, destroy } = await setupServerExperiments(req, res)
  const variation = getFeatureValue(experimentKey, defaultValue)

  destroy()
  return variation
}
