/**
 * Removes all undefined values.
 *
 * Used to create valid JSON objects for getStaticProps.
 * @see https://github.com/vercel/next.js/discussions/11209
 */
export function omitUndefineds<T>(obj: T): T | null {
  // Check if the input is not an object or is null, return null immediately
  if (typeof obj !== 'object' || obj === null) {
    return null
  }

  try {
    return JSON.parse(JSON.stringify(obj)) as T
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error('Failed to omit undefineds', err)
    return null
  }
}

type DeepOmitUndefinedAndNulls<T> = T extends null
  ? null
  : T extends Array<infer U>
  ? Array<DeepOmitUndefinedAndNulls<U>>
  : T extends object
  ? {
      [K in keyof T as T[K] extends undefined ? never : K]: DeepOmitUndefinedAndNulls<T[K]>
    }
  : T

export function omitUndefinedAndNulls<T, R = DeepOmitUndefinedAndNulls<T>>(obj: T): R | null {
  if (typeof obj !== 'object' || obj === null) {
    return null
  }

  if (Array.isArray(obj)) {
    return obj.map(omitUndefinedAndNulls).filter((item) => item !== undefined && item !== null) as R
  }
  if (typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, omitUndefinedAndNulls(value)])
        .filter(([_, value]) => value !== undefined && value !== null),
    ) as R
  }

  return obj as R
}
