import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { ExternalLink } from '@/atoms/ExternalLink'
import { FlexRow } from '@/atoms/FlexContainers'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { SharpPlusIcon } from '@/atoms/Icons/SharpPlusIcon'
import { Image } from '@/atoms/Image'
import { Placeholder } from '@/atoms/Placeholder'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import {
  AsH2,
  AsLabel,
  AsSpan,
  CaptionMD,
  CaptionSM,
  LabelSM,
  ParagraphLG,
  ParagraphSM,
  ParagraphXL,
  TitleLG,
} from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { NamedColor } from '@/constants/types'
import { BasicCarousel } from '@/molecules/BasicCarousel'
import { useGuildUser } from '@/services/GuildUserService'
import { formatPrice } from '@/services/PriceFormatter'
import { ShopifyProduct, useShopifyCollection } from '@/services/Shopify/ShopifyService'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { logger } from '@/utils/logging'
import { getProjectTheme } from '@/utils/project-themes'
import { useTranslate } from '@/utils/translate/translate-client'

export interface MerchDiscount {
  code: string
  value: number
}

interface Utm {
  campaign: string
  linkContext: string
}

export interface HorizontalMerchScrollerProps {
  accessToken: string
  isDarkMode?: boolean
  headerClassName?: string
  discount?: MerchDiscount
  title?: string
  analytics: {
    content_id?: string
    context: string
    program: string
  }
  handle: string
  storeUrl: string
  storeHref?: string | null
  textColor?: NamedColor
  utm?: {
    campaign: string
    linkContext: string
  }
  padding?: boolean
  slug?: string
}

const buildMerchDiscountUrl = (product: ShopifyProduct, discount: MerchDiscount) => {
  try {
    const parsedUrl = new URL(product?.onlineStoreUrl)
    return `${parsedUrl.origin}/discount/${discount.code}?redirect=${parsedUrl.pathname}`
  } catch (error) {
    logger().error('An error occurred creating a merch discount url', error)
    return product?.onlineStoreUrl
  }
}

const buildGuildMerchUrl = (product: ShopifyProduct) => {
  try {
    const parsedUrl = new URL(product?.onlineStoreUrl)
    return `${paths.guild.merchandise}/${parsedUrl.pathname}`
  } catch (error) {
    logger().error('An error occurred creating a guild merch discount url', error)
    return product?.onlineStoreUrl
  }
}

const buildProductUrl = (product: ShopifyProduct, hasMerchDiscount: boolean, discount?: MerchDiscount) => {
  const onlineStoreUrl = product?.onlineStoreUrl

  if (hasMerchDiscount && onlineStoreUrl?.startsWith(paths.external.shop.index)) return buildGuildMerchUrl(product)
  if (discount) return buildMerchDiscountUrl(product, discount)

  return onlineStoreUrl
}

const buildUtmContext = (utm: Utm | undefined) => {
  return utm ? utm.linkContext : 'generic'
}

const buildUtmCampaign = (utm: Utm | undefined) => {
  return utm ? utm.campaign : 'angel-web-project'
}

export const HorizontalShopifyMerchScroller: React.FC<HorizontalMerchScrollerProps> = ({
  accessToken,
  analytics,
  handle,
  storeUrl,
  storeHref,
  textColor,
  utm,
  isDarkMode = true,
  discount,
  headerClassName,
  title,
  padding = false,
  slug,
}) => {
  const { asPath } = useRouter()
  const { isGuildMember, guildRoles } = useGuildUser()
  const { locale } = useLocale()
  const { data, loading, error } = useShopifyCollection({ storeUrl, accessToken, handle })
  const { t } = useTranslate('ads')
  const { track } = useSafeAnalytics()
  const utmLinkContext = buildUtmContext(utm)
  const utmCampaign = buildUtmCampaign(utm)
  const { name: projectName } = getProjectTheme(slug as string)

  if (error) {
    return null
  }

  const visitTheAngelGiftShop = storeHref
    ? t('visitStoreGiftShop', 'Visit {{ projectName }} Gift Shop', {
        projectName,
      })
    : t('visitAngelGiftShop', 'Visit Angel Gift Shop')
  const hasMerchDiscount = Boolean(guildRoles?.hasMerchDiscount)
  const lacksMerchDiscount = Boolean(!guildRoles?.hasMerchDiscount)
  //TODO Add ability to upgrade for guild members who don't have the discount once that is in place
  const showExperiment = Boolean(isDarkMode && (!lacksMerchDiscount || !isGuildMember))
  const joinHref = slug ? `${paths.guild.join}/${slug}` : paths.guild.join

  const massaged =
    data?.collection?.products?.edges
      .map(({ node }) => {
        return {
          id: node.id,
          minPrice: {
            value: node.priceRange.minVariantPrice.amount,
            currency: node.priceRange.minVariantPrice.currencyCode,
          },
          compareAtMinPrice: {
            value: node.compareAtPriceRange.minVariantPrice.amount,
            currency: node.compareAtPriceRange.minVariantPrice.currencyCode,
          },
          title: node.title,
          url: buildProductUrl(node, hasMerchDiscount, discount),
          imageUrl: node.featuredImage.url,
        }
      })
      .filter((item) => item?.url && item?.imageUrl && item?.title) || []

  return (
    <div id="gifts">
      <When condition={!showExperiment}>
        <div className="flex flex-wrap items-center justify-between">
          <FlexRow className={classNames('w-full flex-wrap justify-between gap-2 pb-6', headerClassName)}>
            <TitleLG id="merch-gallery-title" as={AsH2} weight="bold">
              {title ?? t('giftsYoullLove', "Gifts You'll Love")}
            </TitleLG>
            <LinkButton
              href={storeHref ?? paths.external.shop.index}
              variant={isDarkMode ? 'white' : 'lightgray'}
              outline={isDarkMode}
              className="stroke-white !px-5 !py-2.5 text-sm hover:stroke-gray-950"
              onClick={() => {
                track('Clicked Shop For More Gifts', analytics)
              }}
            >
              <span className="hidden md:inline">{visitTheAngelGiftShop}</span>
              <span className="md:hidden">{t('angelGiftShop', 'Angel Gift Shop')}</span>
            </LinkButton>
          </FlexRow>
          <div className="order-1 w-full overflow-x-hidden md:order-2">
            <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
              {loading && <PlaceHolderCards />}
              {massaged?.map((item, index) => {
                const minPrice = formatPrice(item.minPrice.value, {
                  includeDecimals: true,
                  currency: item.minPrice.currency,
                  locale: locale,
                  skipDecimalMath: true,
                })
                const compareAtMinPrice = formatPrice(item.compareAtMinPrice.value, {
                  includeDecimals: true,
                  currency: item.compareAtMinPrice.currency,
                  locale: locale,
                  skipDecimalMath: true,
                })

                return (
                  <div
                    className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
                    key={item.id}
                  >
                    <div>
                      <ExternalLink
                        href={buildAnalyticsLink({
                          href: item.url,
                          path: asPath,
                          campaign: utmCampaign,
                          linkContext: utmLinkContext,
                        })}
                        onClick={() => {
                          track('Clicked Merch Item', {
                            ...analytics,
                            itemName: item.title,
                            itemUrl: item.url,
                            itemPosition: index,
                          })
                        }}
                      >
                        <div className="rounded-xl bg-white/25 p-2">
                          <div className="aspect-h-[180] aspect-w-[248] relative mb-6 w-full rounded-lg bg-white">
                            <div className="flex w-full items-center justify-center">
                              {discount && (
                                <CaptionMD
                                  color="oxide-primary-700"
                                  className="absolute right-4 top-4 rounded-full border border-oxide-200 bg-oxide-100 px-3 py-1"
                                >
                                  {t('savePercentMerch', 'Save {{percent}}%', {
                                    percent: Math.floor(discount?.value * 100),
                                  })}
                                </CaptionMD>
                              )}
                              <img alt={item.title} src={item.imageUrl} className="max-h-full w-auto" loading="lazy" />
                            </div>
                          </div>
                          <ParagraphSM
                            className="mb-4 overflow-x-hidden text-ellipsis whitespace-nowrap !text-base font-semibold"
                            color={textColor ?? 'core-gray-100'}
                          >
                            {item.title}
                          </ParagraphSM>
                          <div className="flex items-center">
                            <div>
                              <LabelSM color={isDarkMode ? 'white' : 'core-gray-700'} as={AsSpan} className="mr-2">
                                {minPrice}
                              </LabelSM>
                              <When condition={minPrice < compareAtMinPrice}>
                                <LabelSM as={AsSpan} color="core-gray-400" className="line-through">
                                  {compareAtMinPrice}
                                </LabelSM>
                              </When>
                            </div>
                            <div className="ml-auto">
                              <Button
                                variant={isDarkMode ? 'copper-600' : 'black'}
                                className={classNames(
                                  'flex items-center px-4 py-[6px]',
                                  isDarkMode && '!border-core-warning-700 !bg-core-warning-700',
                                )}
                              >
                                <LabelSM weight="semibold" color="white" className="cursor-pointer">
                                  {t('buy', 'Buy')}
                                </LabelSM>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ExternalLink>
                    </div>
                  </div>
                )
              })}
            </BasicCarousel>
          </div>
        </div>
      </When>
      <When condition={showExperiment}>
        <div className="flex flex-wrap items-center justify-between">
          <FlexRow
            className={classNames(
              'w-full flex-wrap justify-between gap-2 pb-6',
              { 'px-4 sm:px-8 md:px-12 xl:px-16': padding },
              headerClassName,
            )}
          >
            <TitleLG id="merch-gallery-title" as={AsH2} weight="bold">
              {title ?? t('giftsYoullLove', "Gifts You'll Love")}
            </TitleLG>
            <LinkButton
              href={hasMerchDiscount ? paths.guild.merchandise : storeHref ?? paths.external.shop.index}
              variant={isDarkMode ? 'white' : 'lightgray'}
              outline={!isDarkMode}
              className={classNames('stroke-white !px-5 !py-2.5 text-sm hover:stroke-gray-950', {
                'text-core-gray-950 border-0': isDarkMode,
              })}
              onClick={() => {
                track('Clicked Shop For More Gifts', analytics)
              }}
            >
              <span className="hidden md:inline">{visitTheAngelGiftShop}</span>
              <span className="md:hidden">{t('angelGiftShop', 'Angel Gift Shop')}</span>
            </LinkButton>
          </FlexRow>
          <div className="order-1 w-full overflow-x-hidden md:order-2">
            <BasicCarousel containerClassName={classNames({ 'px-4 sm:px-8 md:px-12 xl:px-16': padding })}>
              {loading && <PlaceHolderCards />}
              <a
                href={joinHref}
                onClick={() => {
                  track('Clicked Guild Join Merch Rail', analytics)
                }}
                className="relative mr-4 flex max-h-full w-2/3 min-w-[256px] max-w-[300px] shrink-0 cursor-pointer snap-center snap-always items-center justify-center overflow-hidden rounded-xl sm:w-2/5 lg:w-3/5"
              >
                <ResponsiveBackgroundImage
                  src={getCloudinaryImageUrl({
                    path: '/v1719423041/angel-studios/merch/guild/show-wall-mock-01-230816_1.png',
                    transforms: 'e_trim',
                  })}
                />
                <div className="relative flex flex-col items-center justify-center gap-[26px] text-center">
                  <Image
                    src={getCloudinaryImageUrl({
                      path: '/v1719424983/angel-studios/merch/guild/Angel_Guild_logo_arc.png',
                      transforms: 'e_trim',
                      height: 82,
                      width: 66,
                    })}
                    alt="guild"
                    width={65}
                    height={82}
                    className="max-h-[82px] max-w-[66px]"
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Image
                      src={getCloudinaryImageUrl({
                        path: '/v1727813059/angel-studios/merch/guild/20-p.webp',
                        transforms: 'e_trim,q_auto:best',
                        height: 255,
                        width: 516,
                      })}
                      className="max-h-[45px] max-w-[125px]"
                      alt="discount"
                      width={125}
                      height={45}
                    />
                    <ParagraphXL color="core-gray-400">{t('allMerchandise', 'all merchandise')}</ParagraphXL>
                  </div>
                  {!hasMerchDiscount && (
                    <LinkButton
                      href={joinHref}
                      variant={isDarkMode ? 'gray-700' : 'lightgray'}
                      outline={!isDarkMode}
                      className={classNames('stroke-white !px-5 !py-2.5 text-sm hover:stroke-gray-950', {
                        'text-white border-0': isDarkMode,
                      })}
                      onClick={() => {
                        track('Clicked Guild Join Merch Rail', analytics)
                      }}
                    >
                      <span className="">{t('joinForDiscounts', 'Join for Discounts')}</span>
                    </LinkButton>
                  )}
                </div>
              </a>
              {massaged?.map((item, index) => {
                const minPrice = formatPrice(item.minPrice.value, {
                  includeDecimals: true,
                  currency: item.minPrice.currency,
                  locale: locale,
                  skipDecimalMath: true,
                })
                const compareAtMinPrice = formatPrice(item.compareAtMinPrice.value, {
                  includeDecimals: true,
                  currency: item.compareAtMinPrice.currency,
                  locale: locale,
                  skipDecimalMath: true,
                })

                return (
                  <div
                    className="w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
                    key={item.id}
                  >
                    <div>
                      <ExternalLink
                        href={buildAnalyticsLink({
                          href: item.url,
                          path: asPath,
                          campaign: utmCampaign,
                          linkContext: utmLinkContext,
                        })}
                        onClick={() => {
                          track('Clicked Merch Item', {
                            ...analytics,
                            itemName: item.title,
                            itemUrl: item.url,
                            itemPosition: index,
                          })
                        }}
                      >
                        <div className="rounded-xl bg-white p-4">
                          <div className="aspect-h-[180] aspect-w-[248] relative w-full rounded-lg bg-white">
                            <div className="flex w-full items-center justify-center">
                              {discount && (
                                <CaptionMD
                                  color="black"
                                  className="absolute right-4 top-4 rounded-full border border-oxide-200 bg-oxide-100 px-3 py-1"
                                >
                                  {t('savePercentMerch', 'Save {{percent}}%', {
                                    percent: Math.floor(discount?.value * 100),
                                  })}
                                </CaptionMD>
                              )}
                              <img alt={item.title} src={item.imageUrl} className="max-h-full w-auto" loading="lazy" />
                            </div>
                          </div>
                          <ParagraphLG
                            className="mb-4 overflow-x-hidden text-ellipsis whitespace-nowrap !text-base"
                            weight="medium"
                            color={'black'}
                          >
                            {item.title}
                          </ParagraphLG>
                          <div className="flex items-center">
                            <div>
                              <ParagraphLG
                                color={isDarkMode ? 'black' : 'core-gray-700'}
                                as={AsSpan}
                                weight="bold"
                                className="mr-2"
                              >
                                {minPrice}
                              </ParagraphLG>
                              <When condition={minPrice < compareAtMinPrice}>
                                <ParagraphLG as={AsSpan} color="black" weight="bold" className="line-through">
                                  {compareAtMinPrice}
                                </ParagraphLG>
                              </When>
                            </div>
                            <div className="ml-auto">
                              <Button
                                variant={isDarkMode ? 'core-gray-900' : 'black'}
                                className={classNames(
                                  'flex items-center px-6 py-[14px]',
                                  isDarkMode && '!border-0 !bg-core-gray-950',
                                )}
                              >
                                <LabelSM weight="semibold" color="white" className="cursor-pointer">
                                  {t('buy', 'Buy')}
                                </LabelSM>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ExternalLink>
                    </div>
                  </div>
                )
              })}
            </BasicCarousel>
          </div>
        </div>
      </When>
    </div>
  )
}

const PlaceHolderCards: React.FC = () => {
  return Array(5)
    .fill(0)
    .map((v, i) => (
      <Placeholder
        key={i}
        placeholderClassName="!bg-transparent"
        className="mr-4 w-2/3 min-w-[256px] max-w-[300px] shrink-0 snap-center snap-always sm:w-2/5 lg:w-3/5"
      >
        <div className="mr-4 rounded-xl bg-white/25 p-2">
          <div className="aspect-h-[180] aspect-w-[248] relative mb-6 w-full rounded-lg bg-white">
            <div className="flex w-full items-center justify-center">
              <GiftIcon className="max-h-full animate-pulse opacity-20" size={128} color="core-gray-950" />
            </div>
          </div>
          <ParagraphSM
            className="mb-6 min-w-8 animate-pulse overflow-x-hidden text-ellipsis whitespace-nowrap rounded-2xl bg-core-gray-950/20"
            color="core-gray-100"
          >
            &nbsp;
          </ParagraphSM>
          <div className="flex items-center">
            <div className="min-w-36 rounded-2xl bg-core-gray-950/20 px-2 py-[2px]">
              <CaptionMD weight="bold" as={AsLabel}>
                &nbsp;
              </CaptionMD>
            </div>
            <div className="ml-auto">
              <div className="flex animate-pulse items-center rounded-xl bg-copper-600/40 px-4 py-[6px]">
                <CaptionSM color="core-gray-100" weight="semibold" as={AsLabel} className="mr-1 cursor-pointer">
                  &nbsp;
                </CaptionSM>
                <SharpPlusIcon color="transparent" />
              </div>
            </div>
          </div>
        </div>
      </Placeholder>
    ))
}
