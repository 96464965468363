import { useCallback } from 'react'
import { getCookie } from 'cookies-next'
import { OptionsType } from 'cookies-next/lib/types'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import { AUTH_JWT_COOKIE, AUTH_SESSION_COOKIE, AUTH_SESSION_COOKIE_0 } from '@/constants/cookies'
import { buildLoginUrl, buildLogoutUrl } from './BuildLoginUrl'
import { buildSignupUrl } from './BuildSignupUrl'
import { useSafeTrack } from './analytics'
import { getStringOrEmptyString, getValidString } from './primitives/strings'

export { buildLoginUrl, buildLogoutUrl } from './BuildLoginUrl'
export { buildSignupUrl } from './BuildSignupUrl'

const resolveReturnPath = (asPath: string, returnTo?: string) => {
  if (returnTo) return returnTo
  if (typeof window !== 'undefined') return window.location.href
  return asPath
}

export function useLoginUrl(returnTo?: string) {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const returnToPath = resolveReturnPath(asPath, returnTo)

  const loginUrl = buildLoginUrl(returnToPath)
  const trackLoginStarted = useCallback(
    (componentName: string) =>
      track('Log In Started', { started_from: componentName, path: asPath, returnTo: returnToPath }),
    [returnToPath, track, asPath],
  )

  return { loginUrl, trackLoginStarted }
}

export function useSignupUrl(returnTo?: string) {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const returnToPath = resolveReturnPath(asPath, returnTo)

  const signupUrl = buildSignupUrl(returnToPath)
  const trackSignupStarted = useCallback(
    (componentName: string) =>
      track('Sign Up Started', { started_from: componentName, path: asPath, returnTo: returnToPath }),
    [returnToPath, track, asPath],
  )

  return { signupUrl, trackSignupStarted }
}

export function useTrackLoginStarted() {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  return useCallback(
    (componentName: string, returnTo?: string) => {
      const returnToPath = returnTo ?? asPath
      track('Log In Started', { started_from: componentName, path: asPath, returnTo: returnToPath })
    },
    [track, asPath],
  )
}

export function useTrackSignupStarted() {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  return useCallback(
    (componentName: string, returnTo?: string) => {
      const returnToPath = returnTo ?? asPath
      track('Sign Up Started', { started_from: componentName, path: asPath, returnTo: returnToPath })
    },
    [track, asPath],
  )
}

export function useLogoutUrl(returnTo?: string) {
  const { asPath } = useRouter()
  const track = useSafeTrack()

  const logoutUrl = buildLogoutUrl(returnTo)

  const trackLogoutStarted = useCallback(
    (componentName: string) => track('Log Out Started', { started_from: componentName, path: asPath }),
    [track, asPath],
  )

  return { logoutUrl, trackLogoutStarted }
}

interface DecodedJwt {
  /** The intended audience of the JWT. This value is currently always "ellis_island" */
  aud: string

  /** The issuer of the JWT. Currently always "ellis_island" */
  iss: string

  /** The receiver of the JWT. In our case, this value is always "angel_web" */
  azp: string

  /** JWT issued at datetime in number format */
  iat: number

  /** JWT expiration datetime in number format */
  exp: number

  /** space delimited list of scopes allowed for this JWT. For example "openid profile email" */
  scope: string

  /** User id */
  sub: string

  /** subject type of the JWT. Currently always "user" */
  subject_type: string

  /** JWT type. Currently always "access" */
  typ: string
}

export function getAccessTokenFromClient(): string {
  return getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE)))
}

export function getJwtFromCookie(): DecodedJwt | null {
  const accessToken = getAccessTokenFromClient()
  return accessToken ? jwtDecode<DecodedJwt>(accessToken) : null
}

export function hasAuthJwtCookie(): boolean {
  return Boolean(getAccessTokenFromClient())
}

export function hasAuthSessionCookie(options?: OptionsType): boolean {
  return Boolean(getCookie(AUTH_SESSION_COOKIE, options)) || Boolean(getCookie(AUTH_SESSION_COOKIE_0, options))
}
