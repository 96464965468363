import LazyHydrate from 'react-lazy-hydration'
import { PortraitRowCollection } from '@/molecules/PortraitRowCollection'
import {
  CatalogTitle,
  findTitleProjectSlug,
  formatTitleLikeVerticalImagePath,
  formatWatchPath,
} from '@/services/ContentCatalog'
import { LinkViewModel, LinksViewProps } from '@/services/RenderService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { logger } from '@/utils/logging'
import { LargePortraitLink } from './LargePortraitLink'

export function LargePortraitRail(props: LinksViewProps) {
  const testId = `large-portrait-rail-${props.id}`
  return (
    <LazyHydrate whenVisible>
      <>
        <PortraitRowCollection id={props.id} testId={testId} slidesToScroll={6} slidesToShow={6} haltLazyLoad>
          {props.links.map((link) => (
            <LargePortraitLink key={link.name} link={link} />
          ))}
        </PortraitRowCollection>
      </>
    </LazyHydrate>
  )
}

LargePortraitRail.mapTitle = mapTitleToLink

export function mapTitleToLink(title: CatalogTitle): LinkViewModel | undefined {
  const imageUrl = getCloudinaryImageUrl({ path: formatTitleLikeVerticalImagePath(title), width: 294, height: 441 })
  const linkUrl = formatWatchPath(title)

  if (!imageUrl || !linkUrl) {
    logger().error(
      `A link view model cannot be created for this title because one or both of imageUrl and linkUrl could not be generated.`,
      { component: LargePortraitRail.name, title, imageUrl, linkUrl },
    )
    return
  }

  return {
    name: title.title,
    alt: title.title,
    imageUrl,
    linkUrl,
    track: {
      eventName: 'Project Tile Clicked',
      payload: {
        name: title.title,
        slug: findTitleProjectSlug(title),
        label: title.description.short,
        tileType: 'large_portrait_rail',
        imageUrl,
        linkUrl,
      },
    },
  }
}
