import React from 'react'
import dynamic, { DynamicOptions } from 'next/dynamic'
import { logger } from '@/utils/logging'
import { BitmovinPlayerProps, BitmovinUILoaderProps } from './types'

export const BitmovinUILoader = dynamic(
  import('./BitmovinUILoader')
    .then((mod) => mod.BitmovinUILoader)
    .catch((err) =>
      logger().error('Failed to load the BitmovinUILoader!', err),
    ) as DynamicOptions<BitmovinUILoaderProps>,
  { ssr: false },
)

export const BitmovinPlayer = dynamic(
  import('./BitmovinPlayer')
    .then((mod) => mod.BitmovinPlayer)
    .catch((err) => logger().error('Failed to load the BitmovinPlayer!', err)) as DynamicOptions<BitmovinPlayerProps>,
  {
    ssr: false,
    loading: () => {
      return (
        <div className="aspect-h-9 aspect-w-16 bg-black lg:aspect-h-unset lg:aspect-w-unset lg:h-[60vh]">
          <div className="w-full bg-black" />
        </div>
      )
    },
  },
)
