import { GetSalesQuery } from '@/types/codegen-contentful'
import { logger } from '@/utils/logging'
import { isDefined } from '@/utils/types'
import {
  ActiveSale,
  VALID_SALE_BANNER_ICONS,
  VALID_SALE_CATEGORIES,
  ValidSale,
  ValidSaleBannerIcon,
  ValidSaleCategory,
} from './types'

export function getActiveSales(result: GetSalesQuery | undefined): ValidSale[] {
  const sales = result?.saleCollection?.items || []
  const validSales = sales.filter(isDefined).map(getValidSale)
  const activeSales = validSales.filter(isDefined).filter(isSaleActive)

  if (activeSales.length) logger().debug('Successfully retrieved active sale objects from Contentful.', { activeSales })

  return activeSales
}

function getValidSale(sale: ActiveSale | null | undefined): ValidSale | undefined {
  if (!sale) return

  const validSale = validateRequiredFields(sale)
  if (!validSale) return

  return validateOptionalFields(validSale, sale)
}

function validateRequiredFields(sale: ActiveSale): ValidSale | undefined {
  if (!sale.name || !sale.category || !sale.pitch || !sale.abbreviatedPitch || !sale.promotionsCollection) {
    logger().error('One or more required properties are missing on the Sale object! This sale will be disregarded.', {
      sale,
    })
    return
  }

  if (!isValidCategory(sale.category)) {
    logger().error('The category value on the Sale object is not supported! This sale will be disregarded.', {
      category: sale.category,
      sale,
    })
    return
  }

  const validSale: ValidSale = {
    id: sale.sys.id,
    name: sale.name,
    category: sale.category,
    abbreviatedPitch: sale.abbreviatedPitch,
    pitch: sale.pitch,
    promotionsCollection: sale.promotionsCollection,
  }

  return validSale
}

function validateOptionalFields(validSale: ValidSale, sale: ActiveSale): ValidSale {
  if (sale.startDate) {
    if (typeof sale.startDate === 'string') validSale.startDate = sale.startDate
    else
      logger().error('Expected sale.startDate to be a string! This field will be omitted.', {
        startDate: sale.startDate,
        sale,
      })
  }

  if (sale.endDate) {
    if (typeof sale.endDate === 'string') validSale.endDate = sale.endDate
    else
      logger().error('Expected sale.endDate to be a string! This field will be omitted.', {
        endDate: sale.endDate,
        sale,
      })
  }

  if (sale.saleBannerIcon) {
    if (isValidSaleBannerIcon(sale.saleBannerIcon)) validSale.saleBannerIcon = sale.saleBannerIcon
    else
      logger().error('The saleBannerIcon value on the Sale object is not supported! This field will be omitted.', {
        saleBannerIcon: sale.saleBannerIcon,
        sale,
      })
  }

  validSale.showSaleBanner = sale.showSaleBanner
  validSale.saleBannerText = sale.saleBannerText
  validSale.showSaleBannerCountdown = sale.showSaleBannerCountdown
  validSale.saleBannerUrl = sale.saleBannerUrl

  return validSale
}

function isValidCategory(category: string): category is ValidSaleCategory {
  return VALID_SALE_CATEGORIES.includes(category as ValidSaleCategory)
}

function isValidSaleBannerIcon(icon: string): icon is ValidSaleBannerIcon {
  return VALID_SALE_BANNER_ICONS.includes(icon as ValidSaleBannerIcon)
}

function isSaleActive(sale: ValidSale): boolean {
  const now = new Date()

  if (sale?.startDate && new Date(sale.startDate) > now) {
    logger().debug(
      'This sale has a start date in the future. This object will be removed since the sale has not started yet.',
      { sale },
    )
    return false
  }

  if (sale?.endDate && new Date(sale.endDate) < now) {
    logger().debug('This sale has an end date in the past. This object will be removed since the sale has ended.', {
      sale,
    })
    return false
  }

  logger().debug('Found an active sale.', { sale })
  return true
}
