import { projectTypes } from '@/constants/projectTypes'
import { slugs } from '@/constants/slugs'
import {
  FlowPhaseForStatusCheck,
  inTheaters,
  isInFreeAccess,
  isInGuildAccess,
  isStreaming,
  willBeInTheaters,
} from '@/services/PhaseManagerService'
import { ProjectMeta } from '@/services/ProjectsService'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { useLocale } from '../LocaleUtil'

const EXCLUDE_FROM_START_WATCHING = new Set([slugs.forYourConsideration, slugs.insideAngelStudios, slugs.theChosen])

const TRAILER_ONLY_PROJECT = new Set([slugs.cabrini])

// Some projects have "Episodes" that are actually teaser trailers, and we don't want those to show up in the Start Watching carousel
export const isProjectExcludedFromStartWatching = (slug: string): boolean => {
  return EXCLUDE_FROM_START_WATCHING.has(slug)
}

const IS_ANGEL_BEHIND_THE_SCENES = new Set([slugs.forYourConsideration, slugs.insideAngelStudios])

// These projects use the Season/Episode structure but they are actually behind the scenes videos, teasers and torches. We don't want to do things like show Attribution for these, and we want to build meta tags correctly as well
export const isAngelBehindTheScenes = (slug: string): boolean => {
  return IS_ANGEL_BEHIND_THE_SCENES.has(slug)
}

export const isTrailerOnlyProject = (slug: string): boolean => {
  return TRAILER_ONLY_PROJECT.has(slug)
}

const getDefaultTitle = (projectType: string, name: string, t: TranslateFunction): string => {
  return projectType === projectTypes.movie
    ? t('watchMovieOnlineDefault', `{{title}} | Watch Movie Online | Angel Studios`, { title: name })
    : t('watchShowOnlineDefault', `{{title}} | Watch Online | Angel Studios`, { title: name })
}

const getGenericDefaultTitle = (name: string, t: TranslateFunction): string => {
  return t('genericProjectDefault', `{{title}} | Angel Studios`, { title: name })
}

const getEarlyAccessTitle = (name: string, t: TranslateFunction): string => {
  return t('earlyAccessStreamingTitleAngel', `{{title}} | Now Streaming for Angel Guild Members | Angel Studios`, {
    title: name,
  })
}

const getFreeAccessTitle = (projectType: string, name: string, t: TranslateFunction): string => {
  return projectType === projectTypes.movie
    ? t('watchMovieOnlineForFree', `{{title}} | Watch Movie Online For Free | Angel Studios`, { title: name })
    : t('watchShowOnlineForFree', `{{title}} | Watch Online For Free | Angel Studios`, { title: name })
}

const getInTheatersTitle = (name: string, t: TranslateFunction, date?: string): string => {
  return t('inTheatersDateTitleAngel', `{{title}} | Coming to Theaters {{date}} | Angel Studios`, { title: name, date })
}

const getInTheatersSoonTitle = (name: string, t: TranslateFunction): string => {
  return t('inTheatersSoonAngel', `{{title}} | Coming to Theaters Soon | Angel Studios`, { title: name })
}

const getInTheatersNowTitle = (name: string, t: TranslateFunction): string => {
  return t('inTheatersNowAngel', '{{title}} | In Theaters Now | Angel Studios', { title: name })
}

function getTheatricalTitle(
  name: string,
  t: TranslateFunction,
  primaryFlowPhases: FlowPhaseForStatusCheck[] | null | undefined,
  locale: string,
): string {
  const theatricalPhase = primaryFlowPhases?.filter((phase) => phase?.phaseSlugEnum === 'theatrical')?.[0] ?? null
  if (theatricalPhase?.releaseWindows?.[0]?.start && !theatricalPhase?.releaseWindows?.[0]?.startSpecificity) {
    return getInTheatersTitle(
      name,
      t,
      new Date(theatricalPhase.releaseWindows[0].start).toLocaleDateString(locale, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }),
    )
  } else {
    return getInTheatersSoonTitle(name, t)
  }
}

const slugTitleMap: {
  [key: string]: { titleFunction: (name: string, t: TranslateFunction) => string }
} = {
  [slugs.insideAngelStudios]: { titleFunction: (name: string) => name },
}

export const useProjectMetaTitle = ({ name, projectType, slug, primaryFlowPhases }: ProjectMeta) => {
  const { t } = useTranslate('watch')
  const { locale } = useLocale()

  let title

  if (slugTitleMap[slug]) {
    title = slugTitleMap[slug].titleFunction(name, t)
  } else if (isInFreeAccess(primaryFlowPhases ?? [])) {
    title = getFreeAccessTitle(projectType, name, t)
  } else if (isInGuildAccess(primaryFlowPhases ?? [])) {
    title = getEarlyAccessTitle(name, t)
  } else if (inTheaters(primaryFlowPhases ?? [])) {
    title = getInTheatersNowTitle(name, t)
  } else if (willBeInTheaters(primaryFlowPhases ?? [])) {
    title = getTheatricalTitle(name, t, primaryFlowPhases, locale)
  } else if (!primaryFlowPhases || (primaryFlowPhases && !isStreaming(primaryFlowPhases ?? []))) {
    title = getGenericDefaultTitle(name, t)
  } else {
    title = getDefaultTitle(projectType, name, t)
  }

  return { title }
}

export const getIsTheatrical = (slug: string) => {
  return slug === slugs.soundOfFreedom
}
