import { Maybe } from '@graphql-tools/utils'
import isString from 'lodash/isString'
import sortBy from 'lodash/sortBy'
import { paths, slugs } from '@/constants'
import locales from '@/constants/locales'
import { StartWatchingProject } from '@/services/ProjectsService/ProjectsService'
import { ProjectContentLink } from '@/services/ProjectsService/types'
import { LinkViewModel, MapDataOptions, PageModel } from '@/services/RenderService/types'
import { isCollectionModel } from '@/services/RenderService/validations'
import { Collection, ContentExperiment, PageContentItem } from '@/types/codegen-contentful'
import { buildLinkToEpisode } from '@/utils/EpisodeUtil'
import { isProjectExcludedFromStartWatching } from '@/utils/SpecialProjectUtils/SpecialProjectUtils'
import { AnalyticsEvent } from '@/utils/analytics'
import { isDevelopmentEnvironment, isProductionEnvironment } from '@/utils/environment-utils'
import { isDefined } from '@/utils/types'
import { isStreaming } from '../PhaseManagerService'

export function getContentAsCollections(page: PageModel) {
  if (!page?.contentCollection?.items) return []
  return page?.contentCollection?.items.filter(isCollectionModel)
}

function isContentExperiment(contentItem?: Maybe<PageContentItem>): boolean {
  return isString(contentItem?.__typename) && contentItem?.__typename === 'ContentExperiment'
}

export function reduceDataSourcesFromContentItem(dataSources: string[], contentItem: Maybe<PageContentItem>) {
  if (isCollectionModel(contentItem) && contentItem.dataSource) {
    return [...dataSources, (contentItem as Collection).dataSource].filter(isDefined)
  }

  if (isContentExperiment(contentItem)) {
    const experiment = contentItem as ContentExperiment

    const experimentDataSources =
      experiment?.variantsCollection?.items
        .filter((item) => item?.contentItemsCollection?.items.some(isCollectionModel))
        .flatMap((item) => item?.contentItemsCollection?.items)
        .filter(isCollectionModel)
        .map((collection) => collection.dataSource)
        .filter(isDefined) ?? []

    return dataSources.concat(experimentDataSources)
  }

  return dataSources
}

export function getCollectionDataSources(page: PageModel): Set<string> {
  if (!page?.contentCollection?.items) return new Set()
  const dataSources: string[] =
    page.contentCollection.items.reduce(reduceDataSourcesFromContentItem, []).filter(isDefined) ?? []
  return new Set(dataSources)
}

// TODO: When we get to ProjectTiles ('brand-posters') we should include the same filter logic there, or lift it to a common place
export function mapProjectsToLogoContentLinks(
  projects: ProjectContentLink[],
  options?: MapDataOptions,
): LinkViewModel[] {
  const sortedProjects = sortBy(projects, (project) => project.sortOrder)

  return (
    sortedProjects
      .filter((project) => {
        if (project.slug === slugs.insideAngelStudios) return true
        if (
          !project ||
          !project?.slug ||
          ((isProductionEnvironment() || isDevelopmentEnvironment()) &&
            !isStreaming((project?.primaryFlowPhases ?? []).filter(isDefined)))
        )
          return
        return slugs.forYourConsideration !== project?.slug
      })
      .filter(Boolean)
      .map((project, index) => {
        const name = project.name
        const linkUrl = `${paths.watch.index}/${project.slug}`

        return {
          imageUrl: project.logoCloudinaryPath,
          landscapeUrl:
            project.slug === slugs.dryBar
              ? 'v1732298855/angel-studios/projects/dry-bar-best-of/517c3affb8348b9b6928c912984aabf2.webp'
              : project?.title?.landscapeAngelImage?.cloudinaryPath ??
                project?.title?.landscapeAngelImage2?.cloudinaryPath ??
                project?.title?.landscapeAngelImage3?.cloudinaryPath ??
                project?.title?.landscapeTitleImage?.cloudinaryPath,
          portraitUrl:
            project.slug === slugs.dryBar
              ? 'v1733422170/angel-studios/projects/dry-bar-best-of/drybar-vert-img-01-lowres.webp'
              : project?.title?.portraitAngelImage?.cloudinaryPath ??
                project?.title?.portraitAngelImage2?.cloudinaryPath ??
                project?.title?.portraitAngelImage3?.cloudinaryPath ??
                project?.title?.portraitTitleImage?.cloudinaryPath,
          alt: name ? `Link to ${project.name}` : undefined,
          name: name ?? undefined,
          linkUrl,
          label: project?.name ? `Link to ${project.name}` : undefined,
          sortOrder: project.sortOrder ?? 0,
          track: {
            eventName: 'Brand Logo Clicked' as AnalyticsEvent,
            payload: { project, railPosition: index, linkUrl, ...options?.analytics },
          },
          projectType: project.projectType ?? '',
        }
      }) || []
  )
}

const startWatchingOrder = [
  slugs.sweetwater,
  slugs.surprisedByOxford,
  slugs.somethingToStandForWithMikeRowe,
  slugs.theShift,
  slugs.cabrini,
  slugs.youngDavid,
  slugs.soundOfHopeTheStoryOfPossumTrot,
  slugs.sight,
  slugs.soundOfFreedom,
  slugs.homestead,
  slugs.wingfeatherSaga,
  slugs.tuttleTwins,
  slugs.hisOnlySon,
  slugs.afterDeath,
  slugs.testament,
  slugs.dryBar,
  slugs.jungleBeat,
  slugs.freelancers,
  slugs.riot,
  slugs.playingForEternity,
  slugs.lightwise,
]

export function mapStartWatchingToContentLinks(
  projects: StartWatchingProject[],
  { locale }: MapDataOptions = { locale: locales.defaultLocale },
): LinkViewModel[] {
  const startWatchingLinks = projects?.map((project) => {
    if (!project || (project?.slug && isProjectExcludedFromStartWatching(project.slug))) return
    const season = project.seasons?.[0]
    if (!season) return
    const episode = season.episodes?.[0]
    if (!episode?.guid) return

    const index = startWatchingOrder.findIndex((slug) => slug === project.slug)
    const sortOrder = index >= 0 ? index : 0 // default to the front of the list with the assumption that promoting new content is useful to users.

    return {
      name: project.name ?? undefined,
      alt: project.name ?? undefined,
      imageUrl: episode.posterLandscapeCloudinaryPath,
      linkUrl: buildLinkToEpisode(
        {
          projectSlug: project.slug as string,
          episodeNumber: episode.episodeNumber as number,
          subtitle: episode.subtitle as string,
          seasonNumber: episode.seasonNumber as number,
          guid: episode.guid,
        },
        { disableDecoration: locale !== locales.defaultLocale },
      ),
      label: episode.subtitle ?? undefined,
      logoUrl: project.logoCloudinaryPath,
      sortOrder,
      track: {
        eventName: 'Clicked Start Watching' as AnalyticsEvent,
        payload: {
          projectSlug: project.slug as string,
          episodeNumber: episode.episodeNumber as number,
          seasonNumber: episode.seasonNumber as number,
          guid: episode.guid,
        },
      },
      metadata: {
        episode,
        project,
      },
    }
  })

  return startWatchingLinks.filter(isDefined).sort((a, b) => {
    return (a?.sortOrder ?? 0) > (b?.sortOrder ?? 0) ? 1 : -1
  })
}
