import { onError } from '@apollo/client/link/error'
import { logger } from '@/utils/logging'

/**
 * Generic ApolloLink for error logging. Attempts to expose more of
 * the two types of error responses that ApolloClient will report
 * and send that data to DataDog.
 * @see https://www.apollographql.com/docs/react/data/error-handling
 */
export const logErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    logger().error('ApolloClient logErrorLink graphQLErrors', {
      graphQLErrors,
      query: operation.query,
      variables: operation.variables,
      operationName: operation.operationName,
      context: operation.getContext(),
    })
    for (const err of graphQLErrors) {
      if (err?.extensions?.code) {
        switch (err.extensions.code) {
          case 'INTERNAL_SERVER_ERROR':
            return forward(operation)
          // Retry failed queries for Internal Server Errors
        }
      }
    }
  }
  if (networkError) {
    logger().error('ApolloClient logErrorLink networkError', {
      networkError,
      query: operation.query,
      variables: operation.variables,
      operationName: operation.operationName,
      context: operation.getContext(),
    })
  }
})
