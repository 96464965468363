import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Else, If, Then, When } from 'react-if'
import { GuildRedeemTicketsCard } from 'src/molecules/GuildRedeemTicketsCard'
import { ButtonVariant, LinkButton } from '@/atoms/Button'
import { ArrowLeftIcon } from '@/atoms/Icons/ArrowLeftIcon'
import { ChatBubbleOutline } from '@/atoms/Icons/ChatBubbleOutline'
import { CircleQuestionIcon } from '@/atoms/Icons/CircleQuestionIcon'
import { LogoutIcon } from '@/atoms/Icons/LogoutIcon'
import { PersonIcon } from '@/atoms/Icons/PersonIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { CaptionMD, CaptionSM, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Avatar } from '@/molecules/Avatar'
import { useGuildUser } from '@/services/GuildUserService'
import { useUser } from '@/services/UserService'
import { useSafeTrack } from '@/utils/analytics'
import { useLoginUrl, useLogoutUrl } from '@/utils/auth-utils'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { getUserFirstName } from '@/utils/users/users'
import { IconLink } from '../IconLink'
import { LanguageAccordion } from '../LanguageAccordion'

interface MenuArrowIconProps {
  isDarkMode: boolean
}

const MenuArrowIcon: FC<MenuArrowIconProps> = ({ isDarkMode }) => (
  <div className="absolute -top-[12px] right-5">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1_467_17013"
        maskUnits="userSpaceOnUse"
        x="0.292893"
        y="0.272078"
        width="24.0416"
        height="24.0416"
        fill="black"
      >
        <rect fill={isDarkMode ? 'black' : 'white'} x="0.292893" y="0.272078" width="24.0416" height="24.0416" />
        <path d="M1 13L12.3137 1.68629L23.6274 13L12.3137 24.3137L1 13Z" />
      </mask>
      <path d="M1 13L12.3137 1.68629L23.6274 13L12.3137 24.3137L1 13Z" fill={isDarkMode ? 'black' : 'white'} />
      <path
        d="M12.3137 1.68629L13.0208 0.979185L12.3137 0.272078L11.6066 0.979185L12.3137 1.68629ZM1.70711 13.7071L13.0208 2.3934L11.6066 0.979185L0.292893 12.2929L1.70711 13.7071ZM11.6066 2.3934L22.9203 13.7071L24.3345 12.2929L13.0208 0.979185L11.6066 2.3934Z"
        fill={isDarkMode ? 'black' : 'white'}
        mask="url(#path-1-outside-1_467_17013)"
      />
    </svg>
  </div>
)

interface AvatarProps {
  loginButtonVariant: ButtonVariant
  onClick?: MouseEventHandler
}

export const UserMenu: React.FC<AvatarProps> = ({ loginButtonVariant, onClick }) => {
  const { user, isLoggedIn, loading } = useUser()
  const { guildRoles } = useGuildUser()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [name, setName] = useState('')
  const { loginUrl, trackLoginStarted } = useLoginUrl()
  const { logoutUrl, trackLogoutStarted } = useLogoutUrl()
  const { t } = useTranslate('common')
  const { isDarkMode } = useThemeContext()
  const track = useSafeTrack()
  const ref = useRef<HTMLDivElement | null>(null)
  const shareGuildCodesModalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (shareGuildCodesModalRef?.current?.contains(event.target as Node)) return

      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (user) {
      const firstName = getUserFirstName(user)
      setName(firstName)
    }
  }, [user])

  const handleTrackingClick = () => {
    track('Clicked User Menu Link')
  }

  const handleClickAwayFromMenu: MouseEventHandler = (e) => {
    e.preventDefault()
    setIsMenuOpen((x) => !x)
  }

  const handleItemClick: MouseEventHandler = (e) => {
    handleTrackingClick()
    setIsMenuOpen((x) => !x)
    if (onClick) onClick(e)
  }

  if (loading) {
    return <div className="w-8" />
  }

  const greeting = t('greeting', 'Hi, {{firstName}}', {
    firstName: name,
  })

  const avatarClasses = classNames(
    isDarkMode ? 'border-black ring-white' : 'border-white ring-black',
    'overflow-hidden rounded-full border-2 bg-cover ring-2 w-fit',
  )

  return (
    <div ref={ref}>
      <If condition={isLoggedIn}>
        <Then>
          <div className="md:mr-6">
            <When condition={isMenuOpen}>
              <div
                className="absolute left-0 top-0 z-[9998] h-screen max-h-full w-screen max-w-full bg-transparent"
                onClick={handleClickAwayFromMenu}
              ></div>
            </When>
            <div className="md:relative">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <div className={classNames(avatarClasses, 'ring-0')}>
                <Avatar onClick={handleItemClick} />
              </div>

              <When condition={isMenuOpen}>
                <div
                  className={classNames(
                    isDarkMode ? 'bg-black text-white ' : 'bg-white text-black',
                    'absolute right-0 top-0 z-[9999] h-screen w-full p-8 shadow-lg md:-right-[15px] md:top-[50px] md:h-fit md:w-[390px] md:rounded-lg',
                  )}
                >
                  <MenuArrowIcon isDarkMode={isDarkMode} />
                  <div onClick={handleItemClick} className="md:hidden">
                    <ArrowLeftIcon color={isDarkMode ? 'white' : 'black'} size={20} />
                  </div>
                  <section>
                    <div className="flex">
                      <div className="my-4">
                        <div className={classNames(avatarClasses, 'md:hidden')}>
                          <Avatar size={80} />
                        </div>
                        <div className={classNames(avatarClasses, 'hidden md:block')}>
                          <Avatar size={64} />
                        </div>
                        <CaptionMD color={isDarkMode ? 'gray-500' : 'gray-600'} className="my-2 text-center font-light">
                          {name !== '' ? name : t('anonymous', 'Anonymous')}
                        </CaptionMD>
                      </div>
                    </div>
                    <div className="flex flex-row items-center space-x-4">
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <TitleSM
                        data-testid="username"
                        className="mb-4 w-52 text-lg font-bold"
                        style={{ overflowWrap: 'break-word' }}
                      >
                        {name !== '' ? greeting : t('welcome', 'Welcome!')}
                      </TitleSM>
                    </div>
                    <IconLink
                      href={paths.account.index}
                      handleClick={handleItemClick}
                      linkText={t('myAccount', 'My Account')}
                    >
                      <PersonIcon size={24} className="m-auto" color="gray-600" />
                    </IconLink>
                    <IconLink
                      href={paths.account.myTickets}
                      handleClick={handleItemClick}
                      linkText={t('myTickets', 'My Tickets')}
                    >
                      <TicketIcon size={24} className="m-auto" color="gray-600" />
                    </IconLink>
                    {guildRoles.hasFreeTickets && (
                      <GuildRedeemTicketsCard modalRef={shareGuildCodesModalRef} isDismissable />
                    )}
                  </section>
                  <section className="my-10">
                    <TitleSM className="mb-4 w-52 text-lg font-bold" style={{ overflowWrap: 'break-word' }}>
                      {t('more', 'More')}
                    </TitleSM>
                    <IconLink
                      href={paths.external.zendesk.support}
                      handleClick={handleItemClick}
                      linkText={t('help', 'Help')}
                      isExternal={true}
                    >
                      <CircleQuestionIcon size={24} color="gray-600" className="m-auto" />
                    </IconLink>
                    <IconLink
                      href={paths.external.zendesk.contactUs}
                      handleClick={handleItemClick}
                      linkText={t('giveFeedback', 'Give Feedback')}
                      isExternal={true}
                    >
                      <ChatBubbleOutline size={24} color="gray-600" className="m-auto" />
                    </IconLink>
                    <LanguageAccordion onClose={() => setIsMenuOpen(!isMenuOpen)} />
                  </section>

                  <section className="mt-10">
                    <IconLink
                      href={logoutUrl}
                      linkText={t('logoutV2', 'Logout')}
                      handleClick={(e) => {
                        trackLogoutStarted('top-nav')
                        handleItemClick(e)
                      }}
                    >
                      <LogoutIcon className="m-auto" color="gray-600" />
                    </IconLink>
                  </section>
                </div>
              </When>
            </div>
          </div>
        </Then>

        <Else>
          <div className="flex items-center">
            <LinkButton
              href={loginUrl}
              variant={loginButtonVariant}
              outline
              className="box-border h-8 rounded-md backdrop-blur-sm"
              onClick={() => trackLoginStarted('top-nav')}
            >
              <CaptionSM className="whitespace-nowrap">
                <Translate t={t} i18nKey="logIn">
                  Log In
                </Translate>
              </CaptionSM>
            </LinkButton>
          </div>
        </Else>
      </If>
    </div>
  )
}
