import { useCallback, useEffect, useMemo, useState } from 'react'
import { QueryHookOptions, QueryResult, useApolloClient, useMutation, useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import {
  UpdateUserProfileInput,
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  UserExpressedInterestQuery,
  UserFinancialsQuery,
  UserProfileQuery,
} from '@/types/codegen-federation'
import { getAnonymousId } from '@/utils/analytics'
import { getJwtFromCookie, hasAuthJwtCookie, hasAuthSessionCookie } from '@/utils/auth-utils'
import { getUserLocation, UserLocation } from '@/utils/location/location'
import { logger } from '@/utils/logging'
import { getWebClient } from '../ApolloClient'
import {
  GET_USER_EXPRESSED_INTEREST,
  GET_USER_PROFILE_QUERY,
  GET_USER_FINANCIALS,
  UPDATE_USER_PROFILE_MUTATION,
} from './queries'

export const MAX_CHAR_FIRST_NAME = 50
export const MAX_CHAR_LAST_NAME = 50
export const MAX_CHAR_CITY = 50
export const MAX_CHAR_STATE = 50

export interface UserAchievements {
  achievedAt: string
  achievementType: 'PIF' | 'SUBSCRIBE' | 'INVEST'
  iconPath: string
  name: string
  projectSlug: string
}

export interface UserProfile {
  user: HydraUser
}

export interface HydraUser {
  email: string
  uuid: string
  isAdmin: boolean
  groups?: { name: string }[]
  profile: {
    firstName: string
    lastName: string
    image: string
    city: string
    state: string
    country: string
    isChosenInvestorSceneClaimed?: boolean
    isChosenPifferSceneClaimed?: boolean
    isChosenSubscriberSceneClaimed?: boolean
    isHosTicketSceneClaimed?: boolean
  }
  userAchievements: UserAchievements[]
  loading: boolean
}

export interface UserGuildMembership {
  user: {
    isGuildMember: boolean
  }
}

/**
 * @deprecated Use useUser from UserService instead.
 */
export const useUserProfile = (options?: QueryHookOptions): QueryResult<UserProfile> => {
  const client = useApolloClient()
  const skip = !hasAuthSessionCookie()
  return useQuery<UserProfile>(GET_USER_PROFILE_QUERY, { client, skip, errorPolicy: 'all', ...options })
}

/**
 * @deprecated Use useUser from UserService instead.
 */
export const useHydraUser = (): HydraUser => {
  const { data, loading } = useUserProfile()

  return data?.user ? { ...data.user, loading } : ({} as HydraUser)
}

export const useUpdateUserProfile = () => {
  const [mutate, updateUserProfileResponse] = useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UPDATE_USER_PROFILE_MUTATION, {
    errorPolicy: 'all',
  })

  const updateUserProfile = useCallback(
    (input: UpdateUserProfileInput) => {
      mutate({ variables: { input } })
    },
    [mutate],
  )

  return useMemo(() => {
    const updatedUser = updateUserProfileResponse?.data?.updateUserProfile
    return { updateUserProfile, updateUserProfileResponse, updatedUser }
  }, [updateUserProfile, updateUserProfileResponse])
}

export type User = NonNullable<UserProfileQuery>['user']
export type UserGroups = NonNullable<NonNullable<UserProfileQuery>['user']>['groups']

export interface UseUserResult extends QueryResult<UserProfileQuery> {
  isLoggedIn: boolean
  user?: User
  userId?: string | null
  groups: UserGroups
  userLocation?: UserLocation
}
export interface UseUserFinancialsResult extends QueryResult<UserFinancialsQuery> {
  userFinancials?: UserFinancialsQuery['user']
}

/**
 * Use a debouncer because this particular hook gets used in MANY components. The useEffect limits the logging
 * to one log entry per component, but we would rather have one log entry per page load. This global debouncer instance
 * spans across all invocations of the useUser hook, so when used in conjunction with a useEffect, the end result
 * is one log entry per page load.
 */
const debouncedCookieLog = debounce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (context: any) => {
    logger().warn(
      `Expected both the jwt cookie and session cookie to be either present or missing, but we have one and not the other!`,
      { ...context, functionLocation: 'UserService.useUser', jwt: getJwtFromCookie(), anonymousId: getAnonymousId() },
    )
  },
  5000,
  { leading: true, trailing: false },
)

const debouncedUserProfileErrorLog = debounce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (context: any, error: any) => {
    logger().error(
      `An unexpected error occurred when attempting to fetch the user profile!`,
      {
        ...context,
        functionLocation: 'UserService.useUser',
        jwt: getJwtFromCookie(),
        anonymousId: getAnonymousId(),
      },
      error,
    )
  },
  5000,
  { leading: true, trailing: false },
)

export const useUser = (options?: QueryHookOptions): UseUserResult => {
  const client = getWebClient()
  const hasJwtCookie = hasAuthJwtCookie()
  const hasSessionCookie = hasAuthSessionCookie()

  useEffect(() => {
    if (hasJwtCookie !== hasSessionCookie) debouncedCookieLog({ hasJwtCookie, hasSessionCookie })
  }, [hasJwtCookie, hasSessionCookie])

  // Don't make the API request if the user is not logged in.
  const skip = !hasJwtCookie
  const [userLocation, setUserLocation] = useState<UserLocation | undefined>()

  const result = useQuery<UserProfileQuery>(GET_USER_PROFILE_QUERY, { client, skip, errorPolicy: 'all', ...options })

  useEffect(() => {
    if (result.error) {
      debouncedUserProfileErrorLog({ hasJwtCookie, hasSessionCookie }, result.error)
    }
  }, [result, hasJwtCookie, hasSessionCookie])

  useEffect(() => {
    getUserLocation().then((location) => {
      setUserLocation(location)
    })
  }, [])

  return useMemo(() => {
    const user = result?.data?.user
    return {
      ...result,
      user,
      userLocation,
      isLoggedIn: !!user?.uuid,
      userId: user?.uuid,
      groups: user?.groups,
    }
  }, [result, userLocation])
}

export const useUserExpressedInterest = () => {
  const { data, loading, refetch } = useQuery<UserExpressedInterestQuery>(GET_USER_EXPRESSED_INTEREST, {
    skip: !hasAuthJwtCookie(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    errorPolicy: 'all',
  })

  return useMemo(() => {
    return { loading, refetch, user: data?.user }
  }, [data, loading, refetch])
}

/**
 * Use a debouncer because this hook gets used in many components. A useEffect alone would limit the logging
 * to one log entry per component, but we would rather have one log entry per page load.
 */
const debouncedUserFinancialsErrorLog = debounce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (context: any, error: any) => {
    logger().error(
      `An unexpected error occurred when attempting to fetch user financial data!`,
      {
        functionLocation: 'UserService.useUserFinancials',
        jwt: getJwtFromCookie(),
        anonymousId: getAnonymousId(),
        hasJwtCookie: hasAuthJwtCookie(),
        hasSessionCookie: hasAuthSessionCookie(),
        ...context,
      },
      error,
    )
  },
  5000,
  { leading: true, trailing: false },
)

export const useUserFinancials = (): UseUserFinancialsResult => {
  const result = useQuery<UserFinancialsQuery>(GET_USER_FINANCIALS, {
    skip: !hasAuthJwtCookie(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (result.error) debouncedUserFinancialsErrorLog({}, result.error)
  }, [result])

  return useMemo(() => {
    return { ...result, userFinancials: result?.data?.user }
  }, [result])
}
