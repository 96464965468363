import React from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSafeAnalytics } from '@/utils/analytics'
import { isProductionEnvironment } from '@/utils/environment-utils'
import { logger } from '../logging'

const noop = (name: string): void => {
  logger().info(`PerfLog noop for ${name}, this should never happen.`)
}

const PerfLogsContext = React.createContext({
  incrementRenderCount: noop,
  incrementUnmountCount: noop,
})

interface PerfLogsProviderProps {
  children: React.ReactNode
}

interface PerfLog {
  renderCount: number
  unmountCount: number
}

type PerfLogs = Record<string, PerfLog>

const isProduction = isProductionEnvironment()

export const PerfLogsProvider: React.FC<PerfLogsProviderProps> = ({ children }) => {
  const { track } = useSafeAnalytics()
  const logs = useRef<PerfLogs>({})

  const doLogging = useCallback(() => {
    Object.entries(logs.current).forEach(([name, log]) => {
      const trackProps = {
        name,
        renderCount: log.renderCount,
        unmountCount: log.unmountCount,
      }

      if (isProduction) {
        track(`Perf Log`, trackProps)
      } else if (!process.env.NEXT_PUBLIC_DISABLE_PERF_LOGS) {
        logger().info(`Perf Log ${name}`, trackProps)
      }
    })
  }, [track])

  useEffect(() => {
    window.addEventListener('beforeunload', doLogging)

    return () => {
      doLogging()
      window.removeEventListener('beforeunload', doLogging)
    }
  }, [doLogging])

  const incrementRenderCount = useCallback(
    (name: string) => {
      if (logs.current[name]) {
        logs.current[name].renderCount++
      } else {
        logs.current[name] = {
          renderCount: 1,
          unmountCount: 0,
        }
      }

      if (!isProduction) {
        logger().info(`Rendering ${name} count=${logs.current[name].renderCount}`)
      }
    },
    [logs],
  )

  const incrementUnmountCount = useCallback(
    (name: string) => {
      if (logs.current[name]) {
        logs.current[name].unmountCount++
      } else {
        logs.current[name] = {
          renderCount: 0,
          unmountCount: 1,
        }
      }

      if (!isProduction) {
        logger().info(`Unmounting ${name} count=${logs.current[name].unmountCount}`)
      }
    },
    [logs],
  )

  const values = useMemo(() => {
    return {
      incrementRenderCount,
      incrementUnmountCount,
    }
  }, [incrementRenderCount, incrementUnmountCount])

  return <PerfLogsContext.Provider value={values}>{children}</PerfLogsContext.Provider>
}

export const usePerfLogs = (name: string) => {
  const context = React.useContext(PerfLogsContext)

  if (!context) {
    throw new Error('usePerfLogs must be used within a PerfLogsProvider!')
  }

  context.incrementRenderCount(name)

  useEffect(() => {
    return () => {
      context.incrementUnmountCount(name)
    }
  }, [context, name])
}
