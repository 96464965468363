// Note that this is a .js file, not .ts, because it is imported by tailwind.config.js

module.exports = {
  colors: {
    black: '#000000',

    'blue-500': '#309DDA',
    'link-blue': '#0645AD',

    'facebook-blue': '#3b5998',
    'twitter-blue': '#00aced',

    white: '#FFFFFF',
    'white-60': 'rgba(255,255,255, 0.6)',

    'gray-100': '#F5F4F3',
    'gray-200': '#EAE9E7',
    'gray-300': '#DDDAD7',
    'gray-400': '#CFCAC6',
    'gray-500': '#A4A09E',
    'gray-600': '#7D7A78',
    'gray-700': '#565453',
    'gray-800': '#41403F',
    'gray-900': '#2A2825',
    'gray-925': '#1D1D1D',
    'gray-950': '#151515',

    transparent: 'transparent',

    'shaded-50': 'rgba(0,0,0,.05)',
    'shaded-150': 'rgba(0,0,0,.15)',
    'shaded-200': 'rgba(0,0,0,.2)',
    'shaded-500': 'rgba(0,0,0,.5)',

    'lightened-50': 'rgba(255,255,255,.05)',
    'lightened-100': 'rgba(255,255,255,.1)',
    'lightened-200': 'rgba(255,255,255,.2)',

    divider: 'rgb(242, 242, 242)',

    'oxide-extra-light': '#CEE9E4',
    'oxide-100': '#D3F5ED',
    'oxide-200': '#21CEA0',
    'oxide-300': '#8CD3C7',
    'oxide-light': '#39C4A6',
    'oxide-bright': '#21CEA0',
    oxide: '#09907A',
    'oxide-dark': '#005E50',
    'oxide-extra-dark': '#05483D',
    'oxide-primary': '#03A67B',
    'oxide-primary-700': '#0E7358',

    'oxide-600': '#78CEAF',
    'oxide-700': '#52AD89',
    'oxide-900': '#2B685D',

    'copper-extra-light': '#FBE5D4',
    'copper-lighter': '#DBA775',
    'copper-light': '#F8B37C',
    copper: '#C48669',
    'copper-dark': '#DB701C',
    'copper-extra-dark': '#8D4B17',

    'copper-100': '#F8F3F1',
    'copper-200': '#F1E7E2',
    'copper-300': '#E4D0C5',
    'copper-400': '#D6B8A8',
    'copper-500': '#C9A18B',
    'copper-600': '#BB896E',
    'copper-700': '#966E58',
    'copper-800': '#705242',
    'copper-900': '#432F25',
    'copper-950': '#251B16',

    'guild-copper': '#4A2B27',

    // His Only Son
    'hos-orange-extra-light': '#F5795C',
    'hos-orange': '#C24629',
    'hos-orange-dark': '#B23615',
    'hos-orange-extra-dark': '#921605',

    // Gabriel and The Guardians
    'gatg-pink': '#E82178',

    // Wingfeather Saga
    'wfs-cream': '#FEE485',
    'wfs-green': '#021419',

    'red-extra-light': '#FDE0E0',
    red: '#E0360B',
    'red-400': '#E26547',
    'red-500': '#f45a3b',
    'red-800': '#C32A2A',

    'warning-50': '#FFF4EB',
    'warning-300': '#FFBF7A',
    'warning-500': '#F29F3D',
    'warning-700': '#B45309',
    'warning-900': '#7D4715',

    'collectible-blue': '#00A4BF',
    'collectible-gold': '#BD9E53',

    success: '#4BB53E',

    'success-100': '#F7FEE7',
    'success-200': '#D1FCAF',
    'success-300': '#D6FB94',
    'success-400': '#BEF264',
    'success-500': '#A3E635',
    'success-600': '#6DAD05',
    'success-700': '#4D7C0F',
    'success-800': '#365314',
    'success-900': '#1D2F07',
    'success-950': '#0F1B01',

    green: '#40BC85',
    teal: '#09907A',
    'teal-light': '#00AB9D',

    // Photon Core
    'core-oxide': '#16B087',
    'core-oxide-20': 'rgba(33, 206, 160, 0.2)',
    'core-oxide-bright': '#21c3a0',
    'core-copper': '#c48669',

    'core-gray-100': '#F5F5F4',
    'core-gray-200': '#E6E6E6',
    'core-gray-300': '#DFDEDD',
    'core-gray-400': '#C3C1C0',
    'core-gray-500': '#9D9C9B',
    'core-gray-600': '#7B7979',
    'core-gray-700': '#595A5B',
    'core-gray-800': '#3B3C3C',
    'core-gray-900': '#262626',
    'core-gray-925': '#1D1D1D',
    'core-gray-950': '#141414',

    'cool-01': '#234749',
    'cool-02': '#00A4BF',
    'cool-03': '#8CD3D7',
    'warm-00': '#FDDED8',
    'warm-01': '#F45A3B',
    'warm-02': '#C6A855',
    'warm-03': '#F29F3D',

    'shadow-01': '#3B3C3C',

    'error-100': '#FEEEEC',
    'error-200': '#FFCCC3',
    'error-300': '#FEA595',
    'error-400': '#FF765B',
    'error-500': '#F45A3B',
    'error-600': '#B9361C',
    'error-700': '#9E240C',
    'error-800': '#711601',
    'error-900': '#450C00',
    'error-950': '#2A0801',

    'core-warning-50': '#FFF4EB',
    'core-warning-200': '#FFDBB4',
    'core-warning-300': '#FFBF7A',
    'core-warning-500': '#F29F3D',
    'core-warning-700': '#B45309',
    'core-warning-800': '#763401',
    'core-warning-900': '#7D4715',

    'secondary-cool': '#00A4BF',
    'secondary-warm': '#F45A3B',

    'info-100': '#F0FFFE',
    'info-200': '#90E5F6',
    'info-220': 'rgba(144, 229, 246, 0.2)',
    'info-300': '#54CBE3',
    'info-400': '#0BC7e6',
    'info-500': '#00A4BF',
    'info-600': '#01859B',
    'info-700': '#095B6D',
    'info-800': '#0F3C4D',
    'info-900': '#03222E',
    'info-950': '#050F15',

    // Bonhoeffer Colors
    'bonhoeffer-dark': '#1E1011',
    'bonhoeffer-primary': '#6B2025',

    // Guild SOF
    'sof-orange-extra-light': '#FFF3EB',
    'sof-red': '#EC2127',
    'sof-orange-light': '#FDF5EA',

    // Homestead Colors:
    'homestead-primary-blue': '#2C2C33',
    'homestead-blue': '#535F66',
    'homestead-gray': '#E2DDCF',
    'homestead-gray-200': '#F0EEE7',
    'homestead-yellow': '#F3B61F',
    'homestead-tan': '#D7C9AA',
    'homestead-tan-200': '#EBE4D4',
    'homestead-red': '#EB2E27',
    'homestead-red-dark': '#D11919',
    'homestead-red-200': '#F59695',

    'brave-primary': '#14293D',
    'brave-subtle': '#3A4C63',

    whatsApp: '#16ab52',

    inherit: 'inherit',

    'giveaway-primary': 'var(--giveaway-primary)',
    'giveaway-primary-light': 'var(--giveaway-primary-light)',
    'giveaway-primary-dark': 'var(--giveaway-primary-dark)',
    'giveaway-primary-accent': 'var(--giveaway-primary-accent)',
    'giveaway-primary-accent-light': 'var(--giveaway-primary-accent-light)',
    'giveaway-primary-accent-dark': 'var(--giveaway-primary-accent-dark)',
    'giveaway-primary-subtle': 'var(--giveaway-primary-subtle)',
    'giveaway-primary-subtle-light': 'var(--giveaway-primary-subtle-light)',
    'giveaway-primary-subtle-dark': 'var(--giveaway-primary-subtle-dark)',
    'giveaway-secondary': 'var(--giveaway-secondary)',
    'giveaway-subtle': 'var(--giveaway-subtle)',
    'giveaway-secondary-light': 'var(--giveaway-secondary-light)',
    'giveaway-secondary-dark': 'var(--giveaway-secondary-dark)',
    'giveaway-secondary-accent': 'var(--giveaway-secondary-accent)',
    'giveaway-secondary-accent-light': 'var(--giveaway-secondary-accent-light)',
    'giveaway-secondary-accent-dark': 'var(--giveaway-secondary-accent-dark)',
    'giveaway-background-primary-light': 'var(--giveaway-background-primary-light)',
    'giveaway-background-primary-dark': 'var(--giveaway-background-primary-dark)',
    'giveaway-background-subtle-light': 'var(--giveaway-background-subtle-light)',
    'giveaway-background-subtle-dark': 'var(--giveaway-background-subtle-dark)',
    'giveaway-background-subtlest-light': 'var(--giveaway-background-subtlest-light)',
    'giveaway-background-subtlest-dark': 'var(--giveaway-background-subtlest-dark)',
    'giveaway-text-primary-light': 'var(--giveaway-text-primary-light)',
    'giveaway-text-primary-dark': 'var(--giveaway-text-primary-dark)',
    'giveaway-text-subtle-light': 'var(--giveaway-text-subtle-light)',
    'giveaway-text-subtle-dark': 'var(--giveaway-text-subtle-dark)',
  },
}
