/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import { AnalyticsInstance, PageData } from 'analytics'
import { useAnalytics, useTrack } from 'use-analytics'
import { isDevelopmentEnvironment, isProductionEnvironment } from '../environment-utils'
import { logger } from '../logging'
import { AnalyticsEvent } from './analytics-event-names'

export type TypedAnalytics = Omit<AnalyticsInstance, 'track'> & { track: TypedTrackFn }

export type TypedTrackFn = (
  eventName: AnalyticsEvent,
  payload?: any,
  options?: any,
  callback?: (...params: any[]) => any,
) => Promise<any>

let memoizedAnalytics: TypedAnalytics | undefined
let memoizedTrackFn: TypedTrackFn | undefined

export function useSafeAnalytics(): TypedAnalytics {
  try {
    // NOTE - the useAnalytics() call throws an error in a test environment.
    const analytics = useAnalytics()

    if (analytics && !memoizedAnalytics) memoizedAnalytics = analytics as TypedAnalytics
    if (memoizedAnalytics) return memoizedAnalytics

    throw new Error('Failed to load analytics module.')
  } catch (err: unknown) {
    if (isProductionEnvironment())
      logger().warn('Failed to load analytics. Falling back to stubbed analytics module.', err)
    return ANALYTICS_STUB
  }
}

export function useSafeTrack(): TypedTrackFn {
  try {
    // NOTE - the useTrack() call throws an error in a test environment.
    const track = useTrack()

    if (isDevelopmentEnvironment()) return ANALYTICS_STUB.track

    if (track && !memoizedTrackFn) {
      memoizedTrackFn = async (eventName, payload, options, cb) => {
        try {
          await track(eventName, payload, options, cb)
        } catch (err) {
          logger().debug('Tracking call failed. Tracking is likely blocked by the client browser.', {
            eventName,
            payload,
            options,
          })
        }
      }
    }
    if (memoizedTrackFn) return memoizedTrackFn

    throw new Error('Failed to load analytics track function.')
  } catch (err: unknown) {
    if (isProductionEnvironment())
      logger().warn('Failed to load analytics track function. Falling back to stubbed track function.', err)
    return ANALYTICS_STUB.track
  }
}

const ANALYTICS_STUB: TypedAnalytics = {
  identify: async (userId: string, traits?: any, options?: any, callback?: (...params: any[]) => any) =>
    console.log('Analytics.identify stub called with args:', { userId, traits, options, callback }),
  track: async (eventName: AnalyticsEvent, payload?: any, options?: any, callback?: (...params: any[]) => any) =>
    console.log('Analytics.track stub called with args:', { eventName, payload, options, callback }),
  page: async (data?: PageData, options?: any, callback?: (...params: any[]) => any) =>
    console.log('Analytics.page stub called with args:', { data, options, callback }),
  user: async (key?: string) => console.log('Analytics.user stub called with args:', { key }),
  reset: async (callback?: (...params: any[]) => any) =>
    console.log('Analytics.reset stub called with args:', { callback }),
  ready: (callback: (...params: any[]) => any) => {
    console.log('Analytics.ready stub called with args:', { callback })
    return () => {
      console.log('Analytics.ready stubbed detach callback invoked')
    }
  },
  on: (name: string, callback: (...params: any[]) => any) => {
    console.log('Analytics.on stub called with args:', { name, callback })
    return () => {
      console.log('Analytics.on stubbed detach callback invoked')
    }
  },
  once: (name: string, callback: (...params: any[]) => any) => {
    console.log('Analytics.once stub called with args:', { name, callback })
    return () => {
      console.log('Analytics.once stubbed detach callback invoked')
    }
  },
  getState: (key?: string) => console.log('Analytics.getState stub called with args:', { key }),
  storage: {
    getItem: (key: string, options?: any) =>
      console.log('Analytics.storage.getItem stub called with args:', { key, options }),
    setItem: (key: string, value: any, options?: any) =>
      console.log('Analytics.storage.setItem stub called with args:', { key, value, options }),
    removeItem: (key: string, options?: any) =>
      console.log('Analytics.storage.removeItem stub called with args:', { key, options }),
  },
  plugins: {
    enable: async (plugins: string | string[], callback?: (...params: any[]) => any) =>
      console.log('Analytics.plugins.enable stub called with args:', { plugins, callback }),
    disable: async (plugins: string | string[], callback: (...params: any[]) => any) =>
      console.log('Analytics.plugins.disable stub called with args:', { plugins, callback }),
  },
}
