/* istanbul ignore file */

// Note that this is a .js file, not .ts, because it is imported by src/services/RedirectsManager/RedirectsManager.js, which is imported by next.config.js

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { slugs } = require('./slugs')

const paths = {
  base: process.env.NEXT_PUBLIC_DOMAIN || 'https://www.angel.com',
  index: '/',
  home: '/home',
  about: {
    index: '/about',
    ourMission: '/about/our-mission',
  },
  ambassadors: {
    index: '/ambassadors',
    cabrini: `/ambassadors/${slugs.cabrini}`,
  },
  app: {
    index: '/app',
  },
  assets: {
    partnerships: { index: '/assets/partnerships', cabrini: '/assets/partnerships/cabrini' },
  },
  auth: {
    accessToken: '/api/auth/accessToken',
    authCallback: '/api/auth/callback',
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    me: '/api/auth/me',
    signup: '/api/auth/signup',
  },
  account: {
    index: '/account',
    collectibles: '/account?t=collectibles',
    collectiblesUnbox: '/account?t=collectibles&unbox=true',
    guild: '/guild',
    guildMembership: '/account/guild-membership',
    guildMembershipPlan: '/account/guild-membership/plan',
    guildMembershipQuote: (offerId) => `/account/guild-membership/quote/${offerId}`,
    myImpact: '/account?t=my-impact',
    payments: '/account?t=payments',
    settings: '/account?t=settings',
    myTickets: '/account?t=myTickets',
    notificationPreferences: '/account/notification-preferences',
  },
  blog: {
    index: '/blog',
    angelStudios: '/blog/angel-studios',
    castToTv: 'https://support.angel.com/hc/articles/10906385450263',
    david: `/blog/${slugs.david}`,
    jungleBeat: `/blog/${slugs.jungleBeat}`,
    ripplesOfFreedom: `/blog/${slugs.soundOfFreedom}/posts/ripples-of-freedom`,
    soundOfFreedom: `/blog/${slugs.soundOfFreedom}`,
    testament: `/blog/${slugs.testament}`,
    theChosen: `/blog/${slugs.theChosen}`,
    tuttleTwins: `/blog/${slugs.tuttleTwins}`,
    wingfeatherSaga: `/blog/${slugs.wingfeatherSaga}`,
  },
  category: {
    index: '/category',
    christianMovies: '/category/christian-movies',
    comedyTvShows: '/category/comedy-tv-shows',
    drama: '/category/drama',
    documentaries: '/category/documentaries',
    free: '/category/free',
    kidsShows: '/category/kids-shows',
  },
  collectibles: {
    index: '/collectibles',
    livestream: '/collectibles/livestream',
    landing: (slug) => {
      return `/collectibles/${slug}`
    },
    shop: (slug) => {
      return `/collectibles/${slug}/shop`
    },
    checkout: (slug) => {
      return `/collectibles/${slug}/checkout`
    },
    success: (slug) => {
      return `/collectibles/${slug}/checkout/success`
    },
  },
  collectiblesMarketplace: {
    index: '/collectibles',
  },
  comingSoon: {
    index: '/coming-soon',
  },
  create: {
    index: '/create',
  },
  exhibitors: {
    index: '/exhibitors',
    cabrini: '/exhibitors/cabrini',
    sight: '/exhibitors/sight',
  },
  filmmakers: {
    index: '/filmmakers',
  },
  franchise: {
    index: '/franchise',
  },
  guild: {
    index: '/guild',
    checkout: '/guild/checkout',
    checkoutSuccess: '/guild/checkout/success',
    drybar: '/guild/join/dry-bar-comedy',
    earlyAccess: '/guild/join/early-access',
    gift: '/guild/gift',
    impact: '/guild/impact',
    investorUpdates: '/guild/investor-updates',
    join: '/guild/join',
    merchandise: '/guild/merchandise',
    pricing: '/guild/pricing',
    tickets: '/guild/tickets',
    updates: '/guild/updates',
    voting: '/guild/voting',
    create: {
      index: '/guild/create',
      torch: '/guild/create/torch',
      status: '/guild/create/status',
    },
    signup: {
      confirm: '/guild/signup/confirm',
      createAccount: '/guild/signup/create-account',
      plan: '/guild/signup/plan',
      pricing: '/guild/signup/pricing',
    },
  },
  legal: {
    index: '/legal',
    devices: '/legal/devices',
    guildMembership: '/legal/guild/membership',
    guildTerms: '/legal/guild',
    marketplaceTermsOfService: '/legal/marketplace-terms-of-service',
    privacyPolicy: '/legal/privacy',
    termsOfUse: '/legal/terms-of-use',
    videoPrivacyPolicy: '/legal/video-privacy-policy',
    videoStoryContestRules: '/legal/video-story-contest-rules',
  },
  movies: {
    index: '/movies',
    afterDeath: '/movies/after-death',
    bonhoeffer: '/movies/bonhoeffer',
    braveTheDark: '/movies/brave-the-dark',
    cabrini: '/movies/cabrini',
    homestead: '/movies/homestead',
    ruleBreakers: '/movies/rule-breakers',
    sight: '/movies/sight',
    soundOfFreedom: '/movies/sound-of-freedom',
    soundOfHopeTheStoryOfPossumTrot: '/movies/sound-of-hope-the-story-of-possum-trot',
    theShift: '/movies/the-shift',
    truthAndTreason: '/movies/truth-and-treason',
  },
  notifications: {
    index: '/notifications',
  },
  partnerships: {
    index: '/partnerships',
    cabrini: '/partnerships/cabrini',
  },
  payItForward: {
    index: '/pay-it-forward',
    theChosen: `/pay-it-forward/${slugs.theChosen}`,
    wingfeatherSaga: `/pay-it-forward/${slugs.wingfeatherSaga}`,
    forYourConsideration: `/pay-it-forward/${slugs.forYourConsideration}`,
  },
  press: {
    index: '/press',
    release: '/press/release',
    truthAndTreason: `/press/${slugs.truthAndTreason}`,
  },
  share: {
    content: '/share/content',
    moment: '/share/moment',
    user: '/share/u',
    story: '/share/story',
    soundOfFreedom: '/share/sound-of-freedom',
  },
  signup: {
    index: '/signup',
    welcome: '/welcome',
    afterDeath: '/signup/after-death',
    angelStudios: '/signup/angel-studios',
    dryBar: '/signup/dry-bar',
    freelancers: '/signup/freelancers',
    gabrielAndTheGuardians: '/signup/gabriel-and-the-guardians',
    germanKing: '/signup/the-german-king',
    possumTrot: '/signup/possum-trot',
    sight: '/signup/sight',
    soundOfHopeTheStoryOfPossumTrot: '/signup/sound-of-hope-the-story-of-possum-trot',
    testament: '/signup/testament',
    theChosen: '/signup/the-chosen',
    theChosenSeason4: '/signup/the-chosen-season-4',
    theShift: '/signup/theshift',
    truthAndConviction: '/signup/truth-and-conviction',
    truthAndTreason: '/signup/truth-and-treason',
    truthAndTreasonSeries: '/signup/truth-and-treason-series',
    tuttleTwins: '/signup/tuttle-twins',
    wingfeatherSaga: '/signup/wingfeather-saga',
  },
  shows: {
    index: '/shows',
    dryBar: `/shows/${slugs.dryBar}`,
    jungleBeat: `/shows/${slugs.jungleBeat}`,
    riotAndTheDance: `/shows/${slugs.riotAndTheDanceSeries}`,
    theChosen: `/shows/${slugs.theChosen}`,
    theChosenSeason4: `/shows/${slugs.theChosen}/season-4`,
    tuttleTwins: `/shows/${slugs.tuttleTwins}`,
    truthAndTreasonSeries: `/shows/${slugs.truthAndTreasonSeries}`,
    wingfeatherSaga: `/shows/${slugs.wingfeatherSaga}`,
    youngDavid: `/shows/${slugs.youngDavid}`,
  },
  preSales: {
    index: (slug) => `/pre-sales/${slug}`,
    checkout: (slug, reservationId) => `/pre-sales/${slug}/checkout/${reservationId}/preview`,
    complete: (slug, reservationId) => `/pre-sales/${slug}/checkout/${reservationId}/complete`,
    giveaway: (slug) => `/pre-sales/${slug}/giveaway`,
  },
  preSalesBacker: {
    index: (slug) => `/pre-sales/${slug}/backer`,
    checkout: (slug, reservationId) => `/pre-sales/${slug}/backer/checkout/${reservationId}/preview`,
    complete: (slug, reservationId) => `/pre-sales/${slug}/backer/checkout/${reservationId}/complete`,
  },
  tickets: {
    index: '/tickets',
    freeTickets: '/freetickets',
    checkout: {
      showtimes: (slug, region) => (region ? `/tickets/${slug}/${region}` : `/tickets/${slug}`),
      seatMap: (slug, showtimeId, reservationId) => {
        if (reservationId) return `/tickets/${slug}/showtime/${showtimeId}/seats/map?reservationId=${reservationId}`
        return `/tickets/${slug}/showtime/${showtimeId}/seats/map`
      },
      seatQuantitySelection: (slug, showtimeId, reservationId) => {
        if (reservationId) {
          return `/tickets/${slug}/showtime/${showtimeId}/seats/quantity?reservationId=${reservationId}`
        }
        return `/tickets/${slug}/showtime/${showtimeId}/seats/quantity`
      },
      summary: (slug, reservationId) => {
        return `/tickets/${slug}/checkout/reservation/${reservationId}/preview`
      },
      complete: (slug, reservationId) => {
        return `/tickets/${slug}/checkout/reservation/${reservationId}/complete`
      },
      reservingTickets: (slug, reservationId) => {
        return `/tickets/${slug}/checkout/reservation/${reservationId}/reserving`
      },
      reservationChange: (slug, reservationId) => {
        return `/tickets/${slug}/checkout/reservation/${reservationId}/change?editReservationId=${reservationId}&flow=angel`
      },
      reservationInvite: (slug, reservationId) => {
        return `/tickets/${slug}/checkout/reservation/${reservationId}/invite`
      },
      reservationInviteShort: (slug, referenceNumber) => {
        return `an.gl/invite/${slug}/${referenceNumber}`
      },
    },
  },
  torches: {
    index: '/torches',
  },
  watch: {
    index: '/watch',
    axiom: `/watch/${slugs.axiom}`,
    bethlehem: `/watch/${slugs.bethlehem}`,
    betweenBorders: `/watch/${slugs.betweenBorders}`,
    beyondTheMedal: `/watch/${slugs.beyondTheMedal}`,
    bonhoeffer: `/watch/${slugs.bonhoeffer}`,
    braveTheDark: `/watch/${slugs.braveTheDark}`,
    cabrini: `/watch/${slugs.cabrini}`,
    david: `/watch/${slugs.david}`,
    dryBar: `/watch/${slugs.dryBar}`,
    dryBarToons: `/watch/${slugs.dryBarToons}`,
    fablehaven: `/watch/${slugs.fablehaven}`,
    faithOfAngels: `/watch/${slugs.faithOfAngels}`,
    forKingAndCountryADrummerBoyChristmasLive: `/watch/${slugs.forKingAndCountryADrummerBoyChristmasLive}`,
    freelancers: `/watch/${slugs.freelancers}`,
    gabrielAndTheGuardians: `/watch/${slugs.gabrielAndTheGuardians}`,
    hisOnlySon: `/watch/${slugs.hisOnlySon}`,
    homestead: `/watch/${slugs.homestead}`,
    homesteadFamilySurvival: `/watch/${slugs.homesteadFamilySurvival}`,
    homesteadSeries: `/watch/${slugs.homesteadSeries}`,
    homesteadSeriesEpisode1: `/watch/${slugs.homesteadSeries}/episode/dee2b65a-ccaa-437b-810d-bbd36bcfd98f`,
    homeToHarmony: `/watch/${slugs.homeToHarmony}`,
    hoovey: `/watch/${slugs.hoovey}`,
    jacob: `/watch/${slugs.jacob}`,
    jungleBeat: `/watch/${slugs.jungleBeat}`,
    kingOfKings: `/watch/${slugs.kingOfKings}`,
    lightwise: `/watch/${slugs.lightwise}`,
    liveNotByLies: `/watch/${slugs.liveNotByLies}`,
    lostOnAMountainInMaine: `/watch/${slugs.lostOnAMountainInMaine}`,
    quitters: `/watch/${slugs.quitters}`,
    riot: `/watch/${slugs.riot}`,
    riotAndTheDanceSeries: `/watch/${slugs.riotAndTheDanceSeries}`,
    ruleBreakers: `/watch/${slugs.ruleBreakers}`,
    somebodysGottaDoIt: `/watch/${slugs.somebodysGottaDoIt}`,
    somethingToStandForWithMikeRowe: `/watch/${slugs.somethingToStandForWithMikeRowe}`,
    soundOfFreedom: `/watch/${slugs.soundOfFreedom}`,
    surprisedByOxford: `/watch/${slugs.surprisedByOxford}`,
    sweetwater: `/watch/${slugs.sweetwater}`,
    testament: `/watch/${slugs.testament}`,
    the1916Project: `/watch/${slugs.the1916Project}`,
    theBlind: `/watch/${slugs.theBlind}`,
    theChosen: `/watch/${slugs.theChosen}`,
    theGermanKing: `/watch/${slugs.theGermanKing}`,
    theLastRodeo: `/watch/${slugs.theLastRodeo}`,
    theLunaticFarmer: `/watch/${slugs.theLunaticFarmer}`,
    theShift: `/watch/${slugs.theShift}`,
    truthAndConviction: `/watch/${slugs.truthAndConviction}`,
    truthAndTreason: `/watch/${slugs.truthAndTreason}`,
    tuttleTwins: `/watch/${slugs.tuttleTwins}`,
    wingfeatherSaga: `/watch/${slugs.wingfeatherSaga}`,
    youngDavid: `/watch/${slugs.youngDavid}`,
  },
  livestream: {
    index: '/livestreams',
  },
  external: {
    accelerationFund: {
      index: 'https://www.angelacceleration.fund/',
    },
    appleAppStore: 'https://apps.apple.com/us/app/angel-studios/id1473663873',
    appleTv: 'https://support.angel.com/hc/en-us/articles/4622534841751-How-to-Download-the-Angel-TV-App-Apple-TV',
    bonhoefferDeclaration: {
      index: 'https://bonhoeffer.org/',
    },
    careers: 'https://jobs.lever.co/angel',
    create: 'https://portal.angel.com/issuer/info',
    fireTv: 'https://support.angel.com/hc/en-us/articles/4637488689431-How-to-Download-the-Angel-TV-App-Fire-TV',
    googlePlayStore: 'https://link.angel.com/zGEh9m8o5Lb',
    googleTv: 'https://support.angel.com/hc/en-us/articles/4633478130839-How-to-Download-the-Angel-TV-App-Google-TV',
    invest: {
      index: 'https://invest.angel.com/',
      axiom: 'https://invest.angel.com/axiom',
      david: 'https://www.thedavidmovie.com/',
      gabrielAndTheGuardians: 'https://invest.angel.com/guardians',
      germanKing: 'https://invest.angel.com/germanking',
      homestead: 'https://invest.angel.com/homestead',
      jacob: 'https://invest.angel.com/jacob',
      liveNotByLies: 'https://invest.angel.com/live',
      privacy: 'https://invest.angel.com/privacy',
      soundOfFreedom: 'https://invest.angel.com/freedom',
      termsOfService: 'https://invest.angel.com/terms',
      testament: 'https://invest.angel.com/testament-2',
      truthAndConviction: 'https://invest.angel.com/truth-2',
    },
    investmentFund: {
      index: 'https://www.angel-reg-a.com/angel-2',
    },
    investorRelations: {
      index: 'https://ir.angel.com/',
      secFilings: 'https://ir.angel.com/sec-filings/',
    },
    launchMovie: {
      index: 'https://launch.movie.com',
      bonhoeffer: 'https://www.launch.movie/bonhoeffer',
    },
    lgTv: 'https://support.angel.com/hc/en-us/articles/23534496032791-How-to-Download-the-Angel-TV-App-LG-TV',
    microsoft: 'https://support.angel.com/hc/articles/24474422103191-How-to-Download-the-Angel-TV-App-Xbox',
    notifyMe:
      'https://docs.google.com/forms/d/e/1FAIpQLSfg4nVLeNGbCYnzFEN2MrCWeT482EwQoNFI5LvrWEAA9e6urQ/viewform?usp=sf_link',
    possumTrotImpact: {
      index: 'https://www.possumtrotimpact.com/',
    },
    preconnect: {
      angelStudiosApi: 'https://api.angelstudios.com',
      angelStudiosImages: 'https://images.angelstudios.com',
      branch: 'https://api2.branch.io',
      bugsnag: 'https://sessions.bugsnag.com',
    },
    roku: 'https://support.angel.com/hc/en-us/articles/4467868786839-How-to-Download-the-Angel-TV-App-Roku',
    samsungTv: 'https://support.angel.com/hc/en-us/articles/23534034041111-How-to-Download-the-Angel-TV-App-Samsung-TV',
    shareVideoStory: 'https://link.angel.com/zj6bYYPnkEb',
    shop: {
      index: 'https://shop.angel.com/',
      account: 'https://shop.angel.com/account',
    },
    shopTheChosen: {
      index: 'https://gifts.angel.com/',
    },
    stripe: {
      monthlyGuildPaymentLink: 'https://pay.angel.com/b/9AQ9EjcuwbYl5lC00a',
      yearlyGuildPaymentLink: 'https://pay.angel.com/b/28ocQvamo7I56pGfZ9',
    },
    zendesk: {
      privacy: 'https://angelstudiosprivacy.zendesk.com/hc/requests/new',
      contactUs: 'https://support.angel.com/hc/requests/new',
      support: 'https://support.angel.com/hc',
    },
  },
}

module.exports = {
  paths,
}
