import { FC, useCallback, useState } from 'react'
import classNames from 'classnames'
import { GuildAccessNotch } from 'src/molecules/GuildAccessNotch'
import { GuildAccessPill } from 'src/molecules/GuildAccessPill'
import { Image } from '@/atoms/Image'
import { PlayButtonThumbnailOverlay } from '@/atoms/PlayButtonThumbnailOverlay'
import { useGuildUser } from '@/services/GuildUserService'
import { UnavailableReasonEnum } from '@/types/codegen-federation'
import { Media } from '@/utils/EpisodeUtil'
import { ProgressOverlay } from './ProgressOverlay'

interface MediaThumbnailProps extends Omit<Media, 'earlyAccessDate' | 'guildAvailableDate' | 'publiclyAvailableDate'> {
  canStreamEarlyAccess?: boolean
  canStreamEpisode?: boolean
  canStreamFreeEpisode?: boolean
  earlyAccessDate?: Date
  earlyAccessHoursBefore?: number
  onCalendarClick?: () => void
  publiclyAvailableDate?: Date
  unavailableReason?: UnavailableReasonEnum
  canStreamGuildAccess?: boolean
  experimentOn?: boolean
  userHasEarlyAccess?: boolean
  hasWatchableReasons?: boolean
  guid?: string
}

export const MediaThumbnail: FC<MediaThumbnailProps> = ({
  aspectClassName,
  earlyAccessDate,
  imgPriority,
  includeHoverScale,
  objectPositionClassName,
  poster,
  posterTransformation,
  progress,
  title,
  unavailableReason,
  publiclyAvailableDate,
  hasWatchableReasons = false,
  guid,
}) => {
  const [isLoadingImage, setLoadingImage] = useState(true)
  const onLoadComplete = useCallback(() => setLoadingImage(false), [])
  const { isGuildMember } = useGuildUser()

  const canStreamEpisode = !unavailableReason
  const canShowGuildPill = isGuildMember

  return (
    <>
      <div
        className={classNames([
          'mb-2 relative bg-cover rounded-lg overflow-hidden duration-[400ms] ease-in-out',
          includeHoverScale ? 'hover:z-10 md:hover:scale-[1.05] cursor-pointer' : '',
        ])}
      >
        <div className={classNames(['relative w-full bg-black rounded-lg', isLoadingImage ? 'animate-pulse' : ''])}>
          <div className={classNames('w-full relative', aspectClassName)}>
            <Image
              alt={title}
              className={classNames([
                'object-cover rounded-lg absolute top-0 left-0 h-full w-full',
                objectPositionClassName,
                { ' opacity-60 ': !canStreamEpisode },
              ])}
              fill
              onLoadingComplete={onLoadComplete}
              priority={imgPriority}
              src={`/${posterTransformation}/${poster}.webp`}
            />
          </div>
          {canStreamEpisode && !!progress && <ProgressOverlay progress={progress} />}
        </div>
        <GuildAccessPill
          earlyAccessDate={earlyAccessDate as Date}
          unavailableReason={unavailableReason}
          isGuildMember={canShowGuildPill}
          publiclyAvailableDate={publiclyAvailableDate as Date}
        />
        <GuildAccessNotch
          earlyAccessDate={earlyAccessDate}
          publiclyAvailableDate={publiclyAvailableDate}
          isGuildMember={isGuildMember}
          guid={guid}
          hasWatchableReasons={hasWatchableReasons}
        />
        {canStreamEpisode && <PlayButtonThumbnailOverlay />}
      </div>
    </>
  )
}
