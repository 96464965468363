import { useMemo } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { GetSalesQuery } from '@/types/codegen-contentful'
import { useLocale } from '@/utils/LocaleUtil'
import { logger } from '@/utils/logging'
import { getContentfulClient } from '../ApolloClient'
import { useGuildUser } from '../GuildUserService'
import { GET_SALES_QUERY } from './queries'
import { getActiveSales } from './shared'
import { ValidSale, ValidSaleCategory } from './types'

interface UseSaleBannerResult {
  error?: ApolloError
  loading: boolean
  saleWithBanner: ValidSale | null
}

const ALL_SALES_DISABLED_PATH_REGEXES = [/\/m\/.*/]
const GUILD_SALE_DISABLED_PATH_REGEXES = [/\/guild.*/, /\/tickets.*/]
const GUILD_MEMBERS_ONLY_SALE_DISABLED_PATH_REGEXES: RegExp[] = []

export function useSaleBanner(): UseSaleBannerResult {
  const { locale } = useLocale()
  const router = useRouter()
  const { isGuildMember } = useGuildUser()

  const opts = { locale, preview: false }
  const queryResult = useQuery<GetSalesQuery>(GET_SALES_QUERY, { variables: opts, client: getContentfulClient(opts) })

  const result = useMemo(() => {
    const { data, loading, error } = queryResult

    if (error) {
      logger().error('An error occurred while attempting to query Sale objects from Contentful!', {}, error)
      return { loading, error, saleWithBanner: null }
    }

    if (loading || !data) {
      return { loading, error, saleWithBanner: null }
    }

    const saleWithBanner = getActiveSaleForUser(data, isGuildMember, router.asPath)

    return { loading, saleWithBanner }
  }, [isGuildMember, queryResult, router])

  return result
}

export function getActiveSaleForUser(
  data: GetSalesQuery,
  isGuildMember: boolean,
  currentPath: string,
): ValidSale | null {
  const activeSales = getActiveSales(data)
  const salesWithBanner = activeSales.filter((s) => s.showSaleBanner)

  const salesForCurrentPath = salesWithBanner.filter((s) => {
    const isCategoryDisabledForPath = (category: ValidSaleCategory, disabledRegexes: RegExp[]): boolean => {
      return s.category === category && disabledRegexes.some((r) => r.test(currentPath))
    }

    if (ALL_SALES_DISABLED_PATH_REGEXES.some((r) => r.test(currentPath))) return false
    if (isCategoryDisabledForPath('guild', GUILD_SALE_DISABLED_PATH_REGEXES)) return false
    if (isCategoryDisabledForPath('guild-members-only', GUILD_MEMBERS_ONLY_SALE_DISABLED_PATH_REGEXES)) return false

    return true
  })

  const salesForThisUser = salesForCurrentPath.filter((s) => {
    if (isGuildMember) {
      return s.category === 'guild-members-only'
    } else {
      return s.category === 'guild'
    }
  })

  const saleWithBanner = salesForThisUser[0]

  if (salesForThisUser.length === 0) {
    logger().debug('There are currently no active sales with a banner that apply to this user.')
    return null
  } else if (salesForThisUser.length === 1) {
    logger().debug('Successfully fetched an active sale with a banner that applies to this user.', { saleWithBanner })
    return saleWithBanner
  } else {
    // Might need some fancy logic here someday, but for now concurrent sales should be an edge case, so just return the first.
    logger().error(
      'There are currently multiple active sales with a banner that apply to this user! Only one will be used.',
      {
        saleWithBanner,
        salesForThisUser,
      },
    )
    return saleWithBanner
  }
}
