'use strict'

// initial code from:
// https://github.com/segmentio/analytics.js-integrations/blob/d08bdacaf1cdfc0a142aff74e4b69c4f205508b1/integrations/facebook-pixel/lib/index.js

/**
 * Module dependencies.
 */
import { Analytics, Plugin } from '@segment/analytics-next'
import { sha256 } from 'js-sha256'
import { camelCase } from 'lodash'
import { DateTime } from 'luxon'
import { Facade, Track } from 'segmentio-facade'
import { z } from 'zod'
import { omitUndefinedAndNulls } from '@/utils/object'

declare global {
  interface Window {
    fbq: facebook.Pixel.Event & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callMethod: {
        apply: (fbq: facebook.Pixel.Event, args: Parameters<facebook.Pixel.Event>) => void
      }
      push: facebook.Pixel.Event
      loaded: boolean
      disablePushState: boolean // disables automatic pageview tracking
      allowDuplicatePageViews: boolean // enables fb
      agent: string
      version: '2.0'
      queue: Parameters<facebook.Pixel.Event>[]
    }
    _fbq: Window['fbq']
  }
}

export type FacebookPixelSettings = {
  automaticConfiguration: boolean
  blacklistPiiProperties: {
    propertyName: string
    hashProperty: boolean
  }[]
  contentTypes: Record<string, string>
  initWithExistingTraits: boolean
  keyForExternalId: ''
  limitedDataUse: boolean
  pixelId: string
  standardEvents: {
    event: string
    standardEventName:
      | 'Search'
      | 'ViewContent'
      | 'ViewContent'
      | 'AddToCart'
      | 'InitiateCheckout'
      | 'Purchase'
      | 'Products Searched'
      | 'Product Viewed'
      | 'Product List Viewed'
      | 'Product Added'
      | 'Checkout Started'
      | 'Order Completed'
    schema: z.ZodSchema
  }[]
  standardEventsCustomProperties: string[]
  userIdAsExternalId: boolean
  agent: string
  valueIdentifier: string
  whitelistPiiProperties: string[]
  versionSettings: {
    version: string
    componentTypes: string[]
  }
  type: string
  bundlingStatus: string
  dataProcessingOptions?: [[string], number, number] | undefined
}

export const defaultFacebookPixelSettings: Omit<FacebookPixelSettings, 'pixelId'> = {
  automaticConfiguration: true,
  blacklistPiiProperties: [
    {
      propertyName: 'userEmail',
      hashProperty: true,
    },
  ],
  contentTypes: {},
  initWithExistingTraits: true,
  keyForExternalId: '',
  limitedDataUse: true,
  standardEvents: [],
  standardEventsCustomProperties: ['funnel', 'fb_login_id', 'projectSlug'],
  userIdAsExternalId: true,
  agent: 'seg',
  valueIdentifier: 'value',
  whitelistPiiProperties: ['email', 'address', 'phone', 'firstName', 'lastName', 'fb_login_id'],
  versionSettings: {
    version: '2.11.5',
    componentTypes: ['browser'],
  },
  type: 'browser',
  bundlingStatus: 'bundled',
}

/**
 * FB requires these date fields be formatted in a specific way.
 * The specifications are non iso8601 compliant.
 * https://developers.facebook.com/docs/marketing-api/dynamic-ads-for-travel/audience
 * Therefore, we check if the property is one of these reserved fields.
 * If so, we check if we have converted it to an iso date object already.
 * If we have, we convert it again into Facebook's spec.
 * If we have not, the user has likely passed in a date string that already
 * adheres to FB's docs so we can just pass it through as is.
 */
const dateFields = [
  'checkinDate',
  'checkoutDate',
  'departingArrivalDate',
  'departingDepartureDate',
  'returningArrivalDate',
  'returningDepartureDate',
  'travelEnd',
  'travelStart',
]

/**
 * FB does not allow sending PII data with events. They provide a list of what they consider PII here:
 * https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking
 * We need to check each property key to see if it matches what FB considers to be a PII property and strip it from the payload.
 * User's can override this by manually whitelisting keys they are ok with sending through in their integration settings.
 */
const defaultPiiProperties = [
  'email',
  'firstName',
  'lastName',
  'gender',
  'city',
  'country',
  'phone',
  'state',
  'zip',
  'birthday',
]

/**
 * Expose `Custom Facebook Pixel` generator.
 */
export function generateCustomFacebookPixel(customFacebookPixelName: `${string}`, options: FacebookPixelSettings) {
  let ready = false

  const CustomFacebookPixel: Plugin = {
    name: customFacebookPixelName,
    version: '0.1.0',
    type: 'destination',

    /**
     * Has this Custom Facebook Pixel library been loaded yet?
     */
    isLoaded(): boolean {
      return !!(typeof window !== 'undefined' && window.fbq && window.fbq.callMethod && ready)
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /**
     * Initialize this Custom Facebook Pixel.
     *
     * @param {Facade} page
     */
    async load(ctx, instance) {
      window._fbq = function (...args: Parameters<facebook.Pixel.Event>) {
        if (window.fbq.callMethod) {
          // eslint-disable-next-line prefer-spread
          window.fbq.callMethod.apply(window.fbq, args)
        } else {
          window.fbq.queue.push(args)
        }
      } as unknown as typeof window.fbq

      window.fbq = window.fbq || window._fbq
      window.fbq.push = window.fbq
      window.fbq.loaded = true
      window.fbq.disablePushState = true // disables automatic pageview tracking
      window.fbq.allowDuplicatePageViews = true // enables fb
      window.fbq.agent = options.agent
      window.fbq.version = '2.0'
      window.fbq.queue = window.fbq.queue ?? []
      if (!options.automaticConfiguration) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.fbq('set', 'autoConfig', false, options.pixelId)
      }
      if (options.limitedDataUse) {
        validateAndSetDataProcessing(options.dataProcessingOptions || [['LDU'], 0, 0])
      } else {
        // explicitly not enable Limited Data Use (LDU) mode
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.fbq('dataProcessingOptions', [])
      }
      if (options.initWithExistingTraits) {
        const traits = formatTraits(instance)
        window.fbq('init', options.pixelId, traits || {})
      } else {
        window.fbq('init', options.pixelId)
      }

      ready = true
    },

    async ready() {
      return ready
    },

    /**
     * Trigger a page view.
     */
    async page(ctx) {
      const track = new Facade(ctx)
      if (true as boolean) return ctx // TODO: remove once facebook pixel is not firing global page view events
      if (typeof window === 'undefined') return ctx
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq('trackSingle', options.pixelId, 'PageView', {}, { eventID: track.proxy('messageId') })
      return ctx
    },

    /**
     * Track an event.
     */
    async track(ctx) {
      const track = new Track(ctx.event)
      const event = track.event()

      const payload = buildPayload(track)

      // Revenue
      if (Object.prototype.hasOwnProperty.call(track.properties(), 'revenue')) {
        payload.value = formatRevenue(track.revenue())
        // To keep compatible with the old implementation
        // that never added revenue to the payload
        delete payload.revenue
      }

      const standard = (options.standardEvents as FacebookPixelSettings['standardEvents'])
        .map((standardEventMapping) => {
          if (standardEventMapping.event !== event) return

          const schema = standardEventMapping.schema
          if (schema && !schema.safeParse(payload).success) return

          return standardEventMapping.standardEventName
        })
        .filter((event) => event !== undefined)

      // non-mapped events get sent as "custom events" with full
      // transformed payload
      if (!standard.length) {
        window.fbq('trackSingleCustom', options.pixelId, event, payload, {
          eventID: track.proxy('messageId') as string,
        })
        return ctx
      }

      // standard conversion events, mapped to one of 9 standard events
      // "Purchase" requires a currency parameter;
      // send full transformed payload
      standard.forEach(function (event) {
        if (!event) return
        if (event === 'Purchase') payload.currency = track.currency() // defaults to 'USD'
        if (
          event in segmentSpecV2EcommerceEvents &&
          segmentSpecV2EcommerceEvents[event as keyof typeof segmentSpecV2EcommerceEvents]
        ) {
          segmentSpecV2EcommerceEvents[event as keyof typeof segmentSpecV2EcommerceEvents](track)
        } else {
          window.fbq('trackSingle', options.pixelId, event, payload, {
            eventID: track.proxy('messageId') as string,
          })
        }
      })

      return ctx
    },
  }

  const segmentSpecV2EcommerceEvents = {
    'Products Searched': productsSearched, // Search
    'Product Viewed': productViewed, // ViewContent
    'Product List Viewed': productListViewed, // ViewContent
    'Product Added': productAdded, // AddToCart
    'Checkout Started': checkoutStarted, // InitiateCheckout
    'Order Completed': orderCompleted, // Purchase
  }

  /**
   * Product List Viewed.
   *
   * @api private
   */
  async function productListViewed(track: Track) {
    let contentType: [string] | undefined
    const contentIds = []
    const contents = []
    const products = track.products()
    const customProperties = buildPayload(track, true)

    // First, check to see if a products array with productIds has been defined.
    if (Array.isArray(products)) {
      products.forEach(function (product) {
        const track = new Track({ properties: product })
        const productId = track.proxy('properties.product_id') || track.productId() || track.id()

        if (productId) {
          contentIds.push(productId)
          contents.push({
            id: productId,
            quantity: track.quantity(),
          })
        }
      })
    }

    // If no products have been defined, fallback on legacy behavior.
    // Facebook documents the content_type parameter decision here: https://developers.facebook.com/docs/facebook-pixel/api-reference
    if (contentIds.length) {
      contentType = ['product']
    } else {
      contentIds.push(track.category() || '')
      contents.push({
        id: track.category() || '',
        quantity: 1,
      })
      contentType = ['product_group']
    }

    window.fbq(
      'trackSingle',
      options.pixelId,
      'ViewContent',
      merge(
        {
          content_ids: contentIds,
          content_type: getContentType(track, contentType),
          contents: contents,
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Product viewed.
   *
   * @api private
   */
  async function productViewed(track: Track) {
    const useValue = options.valueIdentifier === 'value'
    const customProperties = buildPayload(track, true)

    window.fbq(
      'trackSingle',
      options.pixelId,
      'ViewContent',
      merge(
        {
          content_ids: [track.productId() || track.id() || track.sku() || ''],
          content_type: getContentType(track, ['product']),
          content_name: track.name() || '',
          content_category: track.category() || '',
          currency: track.currency(),
          value: useValue ? formatRevenue(track.value()) : formatRevenue(track.price()),
          contents: [
            {
              id: track.productId() || track.id() || track.sku() || '',
              quantity: track.quantity(),
              item_price: track.price(),
            },
          ],
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Product added.
   *
   * @api private
   */
  async function productAdded(track: Track) {
    const useValue = options.valueIdentifier === 'value'
    const customProperties = buildPayload(track, true)

    window.fbq(
      'trackSingle',
      options.pixelId,
      'AddToCart',
      merge(
        {
          content_ids: [track.productId() || track.id() || track.sku() || ''],
          content_type: getContentType(track, ['product']),
          content_name: track.name() || '',
          content_category: track.category() || '',
          currency: track.currency(),
          value: useValue ? formatRevenue(track.value()) : formatRevenue(track.price()),
          contents: [
            {
              id: track.productId() || track.id() || track.sku() || '',
              quantity: track.quantity(),
              item_price: track.price(),
            },
          ],
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Order Completed.
   *
   * @api private
   */
  async function orderCompleted(track: Track) {
    const products = track.products()
    const customProperties = buildPayload(track, true)

    const revenue = formatRevenue(track.revenue())

    // Order completed doesn't have a top-level category spec'd.
    // Let's default to the category of the first product. - @gabriel

    const contentType = getContentType(track, ['product'])
    const contentIds = []
    const contents = []

    for (let i = 0; i < products.length; i++) {
      const trackItem = new Track({ properties: products[i] })
      const pId = trackItem.productId() || trackItem.id() || trackItem.sku()
      contentIds.push(pId)
      const content: { id: unknown; quantity: unknown; item_price?: unknown | undefined } = {
        id: pId,
        quantity: trackItem.quantity(),
      }
      if (trackItem.price()) {
        content.item_price = trackItem.price()
      }
      contents.push(content)
    }

    window.fbq(
      'trackSingle',
      options.pixelId,
      'Purchase',
      merge(
        {
          content_ids: contentIds,
          content_type: contentType,
          currency: track.currency(),
          value: revenue,
          contents: contents,
          num_items: contentIds.length,
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Products Searched.
   *
   * @api private
   */
  async function productsSearched(track: Track) {
    const customProperties = buildPayload(track, true)

    window.fbq(
      'trackSingle',
      options.pixelId,
      'Search',
      merge(
        {
          search_string: track.proxy('properties.query'),
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Checkout Started.
   *
   * @api private
   */
  async function checkoutStarted(track: Track) {
    const products = track.products()
    const contentIds = []
    const contents = []
    let contentCategory = track.category()
    const customProperties = buildPayload(track, true)

    for (let i = 0; i < products.length; i++) {
      const trackItem = new Track({ properties: products[i] })
      const pId = trackItem.productId() || trackItem.id() || trackItem.sku()
      contentIds.push(pId)
      const content = {
        id: pId,
        quantity: trackItem.quantity(),
        item_price: track.price(),
      }
      if (trackItem.price()) {
        content.item_price = trackItem.price()
      }
      contents.push(content)
    }

    // If no top-level category was defined use that of the first product. @gabriel
    if (
      !contentCategory &&
      products[0] &&
      typeof products[0] === 'object' &&
      'category' in products[0] &&
      products[0].category
    ) {
      contentCategory = products[0].category
    }

    window.fbq(
      'trackSingle',
      options.pixelId,
      'InitiateCheckout',
      merge(
        {
          content_category: contentCategory,
          content_ids: contentIds,
          content_type: getContentType(track, ['product']),
          contents: contents,
          currency: track.currency(),
          num_items: contentIds.length,
          value: formatRevenue(track.revenue()),
        },
        customProperties,
      ),
      { eventID: track.proxy('messageId') as string },
    )
  }

  /**
   * Get Traits Formatted Correctly for FB.
   *
   * https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
   *
   * @api private
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function formatTraits(analytics: Analytics) {
    const traits = analytics && analytics.user().traits()
    if (!traits) return {}
    let firstName
    let lastName
    // Check for firstName property
    // else check for name
    if (traits.firstName || traits.first_name) {
      firstName = traits.firstName || traits.first_name
      lastName = traits.lastName || traits.last_name
    } else {
      const nameArray = (traits.name && traits.name.toLowerCase().split(' ')) || []
      firstName = nameArray.shift()
      lastName = nameArray.pop()
    }
    let gender: string | undefined = undefined
    if (traits.gender && typeof traits.gender === 'string') {
      gender = traits.gender.slice(0, 1).toLowerCase()
    }
    const birthday = traits.birthday && DateTime.fromJSDate(new Date(traits.birthday)).toFormat('yyyymmdd')
    const address = traits.address || {}
    const city = address.city && address.city.split(' ').join('').toLowerCase()
    const state = address.state && address.state.toLowerCase()
    const postalCode =
      address.postalCode ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      address.postal_code

    let external_id // eslint-disable-line
    if (options.keyForExternalId) {
      external_id = traits[options.keyForExternalId] // eslint-disable-line
    }
    if (!external_id && options.userIdAsExternalId && analytics) {
      // eslint-disable-line
      external_id = analytics.user().id() || analytics.user().anonymousId() // eslint-disable-line
    }
    return omitUndefinedAndNulls({
      em: traits.email,
      fn: firstName,
      ln: lastName,
      ph: traits.phone,
      ge: gender,
      db: birthday,
      ct: city,
      st: state,
      zp: postalCode,
      external_id: external_id, // eslint-disable-line
    })
  }

  /**
   * Returns an array of mapped content types for the category,
   * the provided value as an integration option or the default provided value.
   *
   * @return Content Type array as defined in:
   * - https://developers.facebook.com/docs/facebook-pixel/reference/#object-properties
   * - https://developers.facebook.com/docs/marketing-api/dynamic-ads-for-real-estate/audience
   */
  function getContentType(track: Track, defaultValue: Array<unknown>) {
    let category = track.category()
    if (!category) {
      // Get the first product's category
      const products = track.products()
      if (
        products &&
        products.length &&
        Array.isArray(products) &&
        products[0] &&
        typeof products[0] === 'object' &&
        'category' in products[0]
      ) {
        category = products[0].category
      }
    }

    if (category && typeof category === 'string') {
      const mapped = options.contentTypes[category]
      if (typeof mapped === 'string' && mapped.length) {
        return [mapped]
      }
    }

    return defaultValue
  }

  /**
   * Builds the FB Event payload. It checks for PII fields and custom properties. If the event is Standard Event,
   * only properties defined in the setting are passed to the payload.
   *
   * @return Payload to send deriveded from the track properties.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function buildPayload(track: Track, isStandardEvent?: boolean) {
    const whitelistPiiProperties = options.whitelistPiiProperties || []
    const blacklistPiiProperties = options.blacklistPiiProperties || []
    const standardEventsCustomProperties = options.standardEventsCustomProperties || []

    // Transforming the setting in a map for easier lookups.
    const customPiiProperties: Record<string, unknown> = {}
    for (let i = 0; i < blacklistPiiProperties.length; i++) {
      const configuration = blacklistPiiProperties[i]
      customPiiProperties[configuration.propertyName] = configuration.hashProperty
    }

    const properties = track.properties()
    const payload: Record<string, unknown> = {}

    for (const property in properties) {
      if (!Object.prototype.hasOwnProperty.call(properties, property)) {
        continue
      }

      // Standard Events only contains custom properties defined in the configuration
      // If the property is not listed there, we just drop it.
      if (isStandardEvent && standardEventsCustomProperties.indexOf(property) < 0) {
        continue
      }

      const value = properties[property]

      // Dates
      if (dateFields.indexOf(camelCase(property)) >= 0) {
        if (value instanceof Date) {
          payload[property] = value.toISOString().split('T')[0]
          continue
        }
      }

      // Custom PII properties
      if (Object.prototype.hasOwnProperty.call(customPiiProperties, property)) {
        // hash or drop
        if (customPiiProperties[property] && typeof value === 'string') {
          payload[property] = sha256(value)
        }
        continue
      }

      // Default PII properties
      const isPropertyPii = defaultPiiProperties.indexOf(property) >= 0
      const isPropertyWhitelisted = whitelistPiiProperties.indexOf(property) >= 0
      if (!isPropertyPii || isPropertyWhitelisted) {
        payload[property] = value
      }
    }

    return payload
  }

  /**
   * Validates that a set of parameters are formatted correctly and passes them to the pixel instance.
   * https://developers.facebook.com/docs/marketing-apis/data-processing-options#reference
   *
   * @param {Array} options
   *
   * @api private
   */
  function validateAndSetDataProcessing(params: [[string], number, number]) {
    const lenOk = params.length === 3
    const valOk = Array.isArray(params[0]) && typeof params[1] === 'number' && typeof params[2] === 'number'

    // Pass the data processing options if they're valid, otherwise, fallback to geolocation.
    if (lenOk && valOk) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq('dataProcessingOptions', params[0], params[1], params[2])
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq('dataProcessingOptions', ['LDU'], 0, 0)
    }
  }

  return CustomFacebookPixel
}

/**
 * Get Revenue Formatted Correctly for FB.
 *
 * @api private
 * @param {Track} track
 */

function formatRevenue(revenue: unknown) {
  return Number(revenue || 0).toFixed(2)
}

/**
 * Merge two javascript objects. This works similarly to `Object.assign({}, obj1, obj2)`
 * but it's compatible with old browsers. The properties of the first argument takes preference
 * over the other.
 *
 * It does not do fancy stuff, just use it with top level properties.
 *
 * @param {Object} obj1 Object 1
 * @param {Object} obj2 Object 2
 *
 * @return {Object} a new object with all the properties of obj1 and the remainder of obj2.
 */
function merge<T extends object, U extends object, R = T & Pick<U, Exclude<keyof U, keyof T>>>(obj1: T, obj2: U): R {
  const res: R = {} as unknown as R

  // All properties of obj1
  for (const propObj1 in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, propObj1)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[propObj1] = obj1[propObj1]
    }
  }

  // Extra properties of obj2
  for (const propObj2 in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, propObj2) && !Object.prototype.hasOwnProperty.call(res, propObj2)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[propObj2] = obj2[propObj2]
    }
  }

  return res
}
